import { Cancel, Check, Close, SkipNext } from '@mui/icons-material';
import { Button, ButtonGroup, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';

const Timer = ({ skipGame, submitGame, lastPanel, expires, message, children, close }) => {
    const [timeLeft, setTimeLeft] = React.useState(Math.max(0, Math.floor((new Date(expires) - Date.now()) / 1000)))
    const [timeString, setTimeString] = React.useState('');
    const [skipDialogOpen, setSkipDialogOpen] = React.useState(false);
    
    const timeDisplay = () => {
        const mins = Math.floor(timeLeft / 60);
        const secs = timeLeft % 60;
        return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`
    }
    useEffect(() => {
        if (timeLeft <= 0) skipGame();

        const intervalId = setInterval(() => {
            setTimeLeft(Math.floor((new Date(expires) - Date.now()) / 1000));
            setTimeString(timeDisplay())
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft])
    return (
        <>
        <Card>
            <CardContent sx={{ p: 2, position: 'relative' }}>
                <Tooltip title={'Closing the game will not stop the timer'}>
                    <IconButton onClick={close} variant="contained" color="error" size="large" sx={{ position: 'absolute', top: 0, right: 0 }}><Cancel fontSize='medium' /></IconButton>
                </Tooltip>

                <Typography variant='h4' fontFamily={'Acme'} align='center'>{message}</Typography>
                <Stack spacing={1} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                    {lastPanel}
                    {children}
                    <Typography variant='h3' fontFamily={'Acme'} align='center'>{timeString}</Typography>
                    <ButtonGroup>
                        <Button variant="contained" color="success" sx={{ minWidth: 'fit-content' }} onClick={submitGame}>
                            <Typography variant="h4" color={'white'} fontFamily={'Acme'}>Submit</Typography>
                        </Button>
                        <Button variant="contained" color="error" sx={{ minWidth: 'fit-content' }} onClick={() => setSkipDialogOpen(true)}>
                            <Typography variant="h4" color={'white'} fontFamily={'Acme'}>Skip</Typography>
                            <SkipNext fontSize='large' />
                        </Button>
                    </ButtonGroup>


                </Stack>

            </CardContent>
        </Card>
        <Dialog open={skipDialogOpen} onClose={() => {setSkipDialogOpen(false)}}>
            <DialogTitle fontFamily={'Acme'}>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText fontFamily={'Acme'}>
                You won't be shown this panel again if you skip
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <IconButton onClick={() => setSkipDialogOpen(false)}>
                    <Close/>
                </IconButton>
                <IconButton onClick={skipGame}>
                    <Check/>
                </IconButton>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default Timer