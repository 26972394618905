import { Box, Button, ButtonGroup, Fab, Grid, SpeedDial, Stack, Typography } from '@mui/material'
import React from 'react'
import emotes from '../panels/Emotes'

const PanelView = ({content}) => {
  return (
    <>
    <Grid container
    justifyContent="center"
    alignItems="center"
    direction={'column'}
    sx={{flexGrow:1, display:{xs:'none', md:'flex'}}}>
        <img src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${content}`} height={512} width={512} />
    </Grid>
    <Grid container
    justifyContent="center"
    alignItems="center"
    direction={'column'}
    sx={{flexGrow:1, display:{xs:'flex', md:'none'}}}>
        <img src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${content}`} height={320} width={320} />
    </Grid>
    </>
    
  )
}

export default PanelView