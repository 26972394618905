import { Outlet } from "react-router-dom";

import React from 'react'
import Footer from "./Banner/Footer";
import Header from "./Banner/Header";

const Layout = () => {
  return (
    <div style={{ paddingBottom: '64px', paddingTop:'96px', position: 'relative', minHeight: '100vh' }}>
        <Header/>
        <Outlet/>
        <Footer/>
    </div>
  );
};

export default Layout;