import React, { useEffect } from 'react'
import { useGetPanelsQuery, useLazyGetPanelsQuery } from '../games/gamesApiSlice';
import PanelSampleLoading from '../../components/Home/PanelSampleLoading';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import PanelSample from '../../components/Home/PanelSample';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/currentUserSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, ArrowForward, FirstPage, LastPage } from '@mui/icons-material';
import AdSenseAd from '../../components/Ads/AdSenseAd';
import PanelSampleAd from '../../components/Ads/PanelSampleAd';

const Home = () => {
  //const token = 
  const [getPanels,{
    data: panelsData,
    error: panelsError,
    isLoading: isPanelsLoading,
    isError: isPanelsError,
    isSuccess: isPanelsSuccess
  }] = useLazyGetPanelsQuery();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(8);
  const [total, setTotal] = React.useState(0);
  const currUser = useSelector(selectCurrentUser);
  useEffect(()=> {
    if(currUser)
      getPanels({usersArray:currUser.profile.following, count, page})
  },[currUser,page]);
  useEffect(()=> {
    if(isPanelsSuccess)
      setTotal(panelsData.total);
  }, [isPanelsSuccess])
  const navigate = useNavigate();

  let content;

  if (isPanelsLoading)
    content =    
    <Grid container spacing={2} sx={{m:2}} alignItems={'center'} justifyContent={'center'}>
      {Array(10).map((i,j,k) => <PanelSampleLoading key={j}/>)}
    </Grid>
  
  if(isPanelsSuccess){
    if(!panelsData?.panels?.length) return <Typography variant='h4' fontFamily={'Acme'} align='center' sx={{m:2}}>There's nothing here! Go check out some <a href='/games' style={{color:'inherit'}}><strong>Finished Games</strong></a> and follow more users!</Typography>
    const panels = panelsData.panels.map((p,i,j) => <PanelSample key={i} panel={p} sx={{mx:2, my:1}} onClick={() => navigate(`/games/id/${p.game._id}`)}/>)
  
    content =
    <Stack direction={'column'} sx={{m:2}}>
      <Typography variant='h3' fontFamily={'Acme'} align='center' sx={{mb:2}}>Home Feed:</Typography>
      <Grid container alignItems={'center'} justifyContent={'center'}>
      {panels}
      </Grid>
      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <IconButton disabled={!page>0} onClick={() => setPage(0)}>
          <FirstPage fontSize='large'/>
        </IconButton>
        <IconButton disabled={!page>0} onClick={() => setPage(page-1)}>
          <ArrowBack fontSize='large'/>
        </IconButton>
        <Typography variant='h6' fontFamily={'Acme'} sx={{mx:5}}>{`${page+1} of ${Math.ceil(total/count)}`}</Typography>
        <IconButton disabled={page>=(Math.ceil(total/count)-1)} onClick={() => setPage(page+1)}>
          <ArrowForward fontSize='large'/>
        </IconButton>
        <IconButton disabled={page>=(Math.ceil(total/count)-1)} onClick={() => setPage(Math.ceil(total/count)-1)}>
          <LastPage fontSize='large'/>
        </IconButton>
      </Stack>
      <AdSenseAd slot={"7297131008"}/>
    </Stack>
  }
  return content;
}

export default Home