import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from './authSlice'
import { useNavigate, Outlet } from 'react-router-dom';


export const RequireLoginLayout = () => {
    const navigate = useNavigate();
    const token = useSelector(selectCurrentToken);
    useEffect(() => {
      if(!token){
        navigate('/login');
      }
    }, [token]);
    return <Outlet/>
}
