import React, { useEffect } from 'react'
import { useGetCurrUserProfileQuery } from '../auth/currentUserApiSlice'
import { Avatar, Badge, Button, Card, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { AddPhotoAlternate, AddPhotoAlternateOutlined } from '@mui/icons-material';
import { useSendVerifyMutation, useSetAvatarMutation, useUpdateUserMutation } from './usersApiSlice';

const Profile = () => {
  //Between 8 and 20 characters long
  //Contains at least one lowercase letter
  //Contains at least one uppercase letter
  //Contains at least one number
  //Contains at least one special character
  const PWD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?-])(?=.*[^\da-zA-Z]).{8,30}$/

  //Block disposable mailboxes
  //Makes sure it's in email format
  const EMAIL_REGEX = /^(?!.*@(guerrillamail\.|guerrillamailblock\.|10minutemail\.|mailinator\.|dispostable\.|discard\.|spambog\.|fakeinbox\.|maildrop\.|tempmail\.|getnada\.|trbvm\.|mailnesia\.|yopmail\.|mvrht\.|meltmail\.|zetmail\.|fleckens\.|pookmail\.|tmails\.|mohmal\.|owlpic\.|mailsac\.|anonbox\.|trashmail\.|mt2015\.))\S+@\S+\.\S+$/

  const errRef = React.useRef();
  const [avatarLocation, setAvatarLocation] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [validPW, setValidPW] = React.useState(true);
  const [emailField, setEmailField] = React.useState('');
  const [validEmail, setValidEmail] = React.useState(true);
  const [marketingEmails, setMarketingEmails] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState('');
  const [isUpdated, setIsUpdated] = React.useState(false);

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
    error: userError
  } = useGetCurrUserProfileQuery();

  const [sendVerify, {
    data: verifyData,
    isSuccess: isVerifySuccess,
    isError, isVerifyError,
    isLoading: isVerifyLoading,
    error: verifyError
  }] = useSendVerifyMutation();

  const [updateUser, {
    data: updateduser,
    isSuccess: isUpdateUserSuccess,
    isError: isUpdateUserError,
    isLoading: isUpdateUserLoading,
    error: updateuserError
  }] = useUpdateUserMutation();

  useEffect(() => {
    if (isUserSuccess) {
      setEmailField(user.email);
      setMarketingEmails(user.marketingEmails || true);
      setAvatarLocation(user.profile.avatar)
    }
  }, [isUserSuccess])

  useEffect(() => {
    if (isUpdateUserSuccess) {
      setOldPassword('');
      setNewPassword('');
      setIsUpdated(true);
    }
  }, [isUpdateUserSuccess])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(emailField));
  }, [emailField]);
  useEffect(() => {
    setValidPW(PWD_REGEX.test(newPassword) || !newPassword.length);
  }, [newPassword]);

  const canSubmit = validEmail && validPW && !(isUpdateUserLoading || isUserLoading || isVerifyLoading);

  const onSubmit = () => {
    const userData = { password: oldPassword, newPassword: newPassword, email: emailField, marketingEmails };
    updateUser({ userId: user._id, userData: userData });
  }


  let content;
  if (isUserLoading) content = <p>Loading...</p>
  if (isUserError) content = <p>{userError?.data?.message}</p>
  if (isUserSuccess) {
    const { birthday, email, premium, profile, tokens, username, verifiedEmail } = user;
    content =
      <Grid container direction='column' alignItems='center' justifyContent='center'>
        <Typography variant="h4" fontFamily={'Acme'} align="center">{`${username}'s Profile`}</Typography>
        <Avatar src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${avatarLocation}.png`} sx={{ width: 192, height: 192 }} />
        <Grid item>
          <Card raised sx={{ m: 2, py: 2, px: 3 }}>
            <form>
              <Typography variant="h6" fontFamily={'Acme'} align="center">{`Edit Profile`}</Typography>
              <Grid container direction='column' alignContent='center' justifyItems='center' rowGap={1.5}>
                <Typography color="error" sx={{ my: 1 }} ref={errRef} aria-live="assertive">{errMsg}</Typography>
                <TextField
                  label='Birthday'
                  disabled
                  defaultValue={new Date(birthday).toDateString()} />
                <TextField
                  label='Email'
                  value={emailField}
                  onChange={e => { setEmailField(e.target.value); setIsUpdated(false) }}
                  autoComplete='off'
                  helperText={user.verifiedEmail ? null : "Unverified Email"}
                  error={!validEmail} />
                {
                  user.verifiedEmail ?
                    null :
                    <Button
                      onClick={() => { sendVerify({ userId: user._id }) }}
                      color={isVerifySuccess ? 'success' : isVerifyError ? 'error' : 'primary'}
                    >
                      {isVerifySuccess ? 'Sent' : isVerifyError ? 'Error Sending' : 'Resend Verification'}
                    </Button>
                }

                <TextField
                  label='New Password'
                  type='password'
                  value={newPassword}
                  onChange={e => { setNewPassword(e.target.value); setIsUpdated(false) }}
                  inputProps={{ maxLength: 32 }}
                  autoComplete={"new-password"}
                  error={!validPW} />
                <TextField
                  label='Current Password'
                  required
                  type='password'
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                  autoComplete='on' />
                <FormControlLabel
                  label={<Typography variant="body2" align="left" fontFamily={'Acme'}>Receive Marketing Emails</Typography>}
                  control={<Checkbox checked={marketingEmails} onChange={(e) => {setMarketingEmails(e.target.checked); setIsUpdated(false)}} />}
                  labelPlacement="end"
                />
                <Button type='outlined' disabled={!canSubmit} color={isUpdated ? 'success' : 'primary'} onClick={onSubmit}>
                  <Typography fontFamily={'Acme'} align='center'>{isUpdated ? "Success" : "Submit"}</Typography>
                </Button>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
  }

  return content;
}

export default Profile