import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateNewUserMutation } from "./usersApiSlice";
import React from 'react'
import { Button, Checkbox, FormControlLabel, Grid, Link, List, ListItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { ArrowRight, Send } from "@mui/icons-material";
import dayjs from "dayjs"

//any character can be upper case letter, lower case letter, or number
//3 to 20 characters
const USER_REGEX = /^[a-zA-Z0-9]{5,16}$/

//Between 8 and 20 characters long
//Contains at least one lowercase letter
//Contains at least one uppercase letter
//Contains at least one number
//Contains at least one special character
const PWD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?-])(?=.*[^\da-zA-Z]).{8,30}$/

//Block disposable mailboxes
//Makes sure it's in email format
const EMAIL_REGEX = /^(?!.*@(guerrillamail\.|guerrillamailblock\.|10minutemail\.|mailinator\.|dispostable\.|discard\.|spambog\.|fakeinbox\.|maildrop\.|tempmail\.|getnada\.|trbvm\.|mailnesia\.|yopmail\.|mvrht\.|meltmail\.|zetmail\.|fleckens\.|pookmail\.|tmails\.|mohmal\.|owlpic\.|mailsac\.|anonbox\.|trashmail\.|mt2015\.))\S+@\S+\.\S+$/


const SignUpForm = (props) => {

  const { onModalClose } = props;
  const [addNewUser, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCreateNewUserMutation();

  const navigate = useNavigate();

  const [invite, setInvite] = useState('');
  const [username, setUsername] = useState('');
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [validRetypePassword, setValidRetypePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [birthday, setBirthday] = useState(null);
  const [validBirthday, setValidBirthday] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [marketingEmails, setMarketingEmails] = useState(true);

  const today = new Date();
  const minBirthday = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username))
  }, [username]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password))
  }, [password]);

  useEffect(() => {
    setValidRetypePassword(password === retypePassword)
  }, [password, retypePassword]);

  useEffect(() => {
    setValidBirthday(birthday < minBirthday)
  }, [birthday, minBirthday]);

  useEffect(() => {
    if (isSuccess) {
      setUsername('');
      setEmail('');
      setPassword('');
      setBirthday(null);
      navigate('/home');
    }
  }, [isSuccess, navigate]);

  const isUsernameError = Boolean(username.length && !validUsername);
  const isPasswordError = Boolean(password.length && !validPassword);
  const isRetypePasswordError = !validRetypePassword;
  const isEmailError = Boolean(email.length && !validEmail);
  const isBirthdayError = !validBirthday;
  const isInviteError = invite.length !== 12;

  const onInviteChanged = e => setInvite(e.target.value);
  const onUsernameChanged = e => setUsername(e.target.value);
  const onPasswordChanged = e => setPassword(e.target.value);
  const onRetypePasswordChanged = e => setRetypePassword(e.target.value);
  const onEmailChanged = e => setEmail(e.target.value);
  const onTosCheckedChange = e => setTosChecked(e.target.checked);
  const canSave = [validEmail, validUsername, validPassword, validBirthday, tosChecked, validRetypePassword].every(Boolean) && !isLoading;

  const onSaveUserClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewUser({ username, email, password, birthday, marketingEmails, invite})
    }
  }

  const StyledListItem = ({ children }) => (
    <ListItem style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
      <ArrowRight sx={{ paddingRight: "4px" }} />
      <Typography variant="caption">{children}</Typography>
    </ListItem>
  );

  const content = (
    <form onSubmit={onSaveUserClicked} style={{marginBottom:'1rem'}}>
      <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
        <Typography variant="h3" fontFamily={'Acme'} align="center">Sign Up</Typography>
        <Typography color="error" display={isError ? 'flex' : 'none'}>{error?.data?.message}</Typography>
        <Tooltip arrow placement="bottom" title={<Typography>While we're in beta you'll need an invite code to join!</Typography>}>
          <TextField label='Invite Code' onChange={onInviteChanged} variant="standard"/>
        </Tooltip>
        <Tooltip arrow placement="bottom" title={
          <List>
            <StyledListItem>Username must be between 5 and 16 characters</StyledListItem>
            <StyledListItem>Username cannot contain spaces or punctuation</StyledListItem>
            <StyledListItem>Don't use your real name!</StyledListItem>
          </List>
        }>
          <TextField
            label="Username"
            error={isUsernameError}
            onChange={onUsernameChanged}
            helperText={isUsernameError ? 'Invalid username' : ''}
            inputProps={{ maxLength: 16 }}
            variant="standard"
            autoComplete="username"
          />
        </Tooltip>
        <Tooltip arrow placement="bottom" title={
          <List>
            <StyledListItem>You must provide a valid email address</StyledListItem>
            <StyledListItem>We will never share your email</StyledListItem>
          </List>
        }>
          <TextField
            label="Email"
            error={isEmailError}
            onChange={onEmailChanged}
            helperText={isEmailError ? 'Invalid email' : ''}
            variant="standard"
            autoComplete="email"
          />
        </Tooltip>
        <Tooltip arrow placement="bottom" title={
          <>
            <Typography variant="subtitle2">Password must contain:</Typography>
            <List>
              <StyledListItem>Between 8 and 30 characters</StyledListItem>
              <StyledListItem>At least one lowercase letter</StyledListItem>
              <StyledListItem>At least one uppercase letter</StyledListItem>
              <StyledListItem>At least one number</StyledListItem>
              <StyledListItem>At least one special character</StyledListItem>
            </List>
          </>
        }>
          <TextField
            label="Password"
            type="password"
            error={isPasswordError}
            onChange={onPasswordChanged}
            helperText={isPasswordError ? 'Invalid password' : ''}
            variant="standard"
            autoComplete="new-password"
          />
        </Tooltip>
        <TextField
          label="Confirm Password"
          type="password"
          error={isRetypePasswordError}
          onChange={onRetypePasswordChanged}
          helperText={isRetypePasswordError ? 'Passwords must match!' : ''}
          variant="standard"
          autoComplete="new-password"
        />
        <Tooltip arrow placement="bottom" title={
            <Typography>Your birthday is used to determine if you have access to age-restricted content</Typography>
        }>
          <DateField
            label="Birthday"
            onChange={(e) => setBirthday(new Date(e))}
            value={dayjs(birthday)}
            helperText={isBirthdayError ? 'User must be at least 13 to join' : ''}
            disableFuture
            minDate={dayjs("1900-1-1")}
            variant="standard"
            autoComplete="bday"
          />
        </Tooltip>
        <Stack alignItems={'start'} justifyContent={'start'} alignSelf={'center'} sx={{px:'20%'}}>
          <FormControlLabel
            label={<Typography variant="body2" align="left" fontFamily={'Acme'}>I agree to the <Link href="/TermsAndConditions.txt" target="_blank">Terms and Conditions</Link> and <Link href="/PrivacyPolicy.txt" target="_blank">Privacy Policy</Link></Typography>}
            control={<Checkbox checked={tosChecked} onChange={onTosCheckedChange} />}
            labelPlacement="end"
          />
          <FormControlLabel
            label={<Typography variant="body2" align="left" fontFamily={'Acme'}>I agree for ArtRush to send me marketing emails</Typography>}
            control={<Checkbox checked={marketingEmails} onChange={(e) => setMarketingEmails(e.target.checked)}/>}
            labelPlacement="end"
          />
        </Stack>
        <Button disabled={!canSave} onClick={onSaveUserClicked} endIcon={<Send />} variant="outlined">Submit</Button>
      </Stack>
    </form>
  )

  return (
    content
  )
}

export default SignUpForm