import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../../features/auth/authSlice';
import BannerLoggedIn from './BannerLoggedIn';
import BannerLoggedOut from './BannerLoggedOut';


const Header = () => {

    const token = useSelector(selectCurrentToken);
    const content = token ? <BannerLoggedIn/> : <BannerLoggedOut/>;

    return content;

}

export default Header