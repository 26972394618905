import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const gamesAdapter = createEntityAdapter({})
const initialState = gamesAdapter.getInitialState();

export const gamesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGames: builder.query({
            query: (page) => `/games/new/${page ? page : ""}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData => {
                const loadedGames = responseData.map(game => {
                    game.id = game._id;
                    return game;
                });
                return gamesAdapter.setAll(initialState, loadedGames);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Game', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Game', id }))
                    ];
                } else return [{ type: 'Game', id: 'LIST' }];
            }
        }),
        getGame: builder.query({
            query: (id) => `/games/${id}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            /*
            transformResponse: responseData => {
                const loadedGame = {...responseData, id:responseData._id};
                return gamesAdapter.upsertOne(initialState, loadedGame);
            },
            providesTags: (result, error, arg) => {
                return [{type: 'Game', id: result.id}];
            }
            */
        }),
        getPlayableGame: builder.mutation({
            query: (id) => ({
                url: `/games/play/${id ? id : ""}`,
                method: 'PUT',
            })
        }),
        submitGame: builder.mutation({
            query: gameBody => ({
                url: `/games/submit/${gameBody.id}`,
                method: 'PUT',
                body: { ...gameBody }
            })
        }),
        newGame: builder.mutation({
            query: gameBody => ({
                url: '/games',
                method: 'POST',
                body: { ...gameBody }
            })
        }),
        skipGame: builder.mutation({
            query: (gameBody) => ({
                url: `/games/submit/${gameBody.id}`,
                method: 'PATCH'
            })
        }),
        likeGame: builder.mutation({
            query: (reqBody) => ({
                url: `/games/${reqBody.id}`,
                method: 'PATCH',
                body: { like: reqBody.like },
            }),
        }),
        getPanels: builder.query({
            query: ({ usersArray, count = 16, page = 0 }) => `/games/home?count=${count}&page=${page}&${usersArray?.map(i => { return `userId=${i}` }).join('&')}`,
        }),
        emotePanel: builder.mutation({
            query: ({emote,panel}) => ({
                url: '/games/panel',
                method: 'PATCH',
                body: {emote, panel}
            })
        })
    })
});

export const {
    useGetGamesQuery,
    useLazyGetGamesQuery,
    useGetGameQuery,
    useLazyGetGameQuery,
    useGetPlayableGameMutation,
    useSubmitGameMutation,
    useNewGameMutation,
    useSkipGameMutation,
    useLikeGameMutation,
    useGetPanelsQuery,
    useLazyGetPanelsQuery,
    useEmotePanelMutation
} = gamesApiSlice;

// returns query result object
export const selectGamesResult = gamesApiSlice.endpoints.getGames.select();

//creates memoized selector
const selectGamesData = createSelector(
    selectGamesResult,
    gamesResult => gamesResult.data
)

//getSelectors creates selectors which are renamed with aliases during destructuring
export const {
    selectAll: selectAllGames,
    selectById: selectGameById,
    selectIds: selectGameIds
    // pass in a selector that returns the games slice of state
} = gamesAdapter.getSelectors(state => selectGamesData(state) ?? initialState);