import React from 'react'
import { selectGameById } from '../gamesApiSlice';
import { useSelector } from 'react-redux';
import { Card, Grid, Paper, Stack, Typography } from '@mui/material';
import PanelSample from '../../../components/Home/PanelSample';
import { useNavigate } from 'react-router-dom';

const GameItem = ({ gameId }) => {

    const game = useSelector(state => selectGameById(state, gameId));
    const navigate = useNavigate();

    if (game)
        return (
            <Card raised>
                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{m:2}}>
                    <Grid item>
                        <Typography variant='h5' fontFamily={'Acme'}>{game?.gameMode}</Typography>
                    </Grid>
                    <Grid item container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                        <Grid item >
                            <PanelSample showAvatar={false} panel={game?.firstPanel} gameId={gameId} onClick={() => navigate(`/games/id/${gameId}`)} responsive={true}/>
                        </Grid>
                        <Grid item >
                            <PanelSample showAvatar={false} panel={game?.lastPanel} gameId={gameId} onClick={() => navigate(`/games/id/${gameId}`)} responsive={true}/>
                        </Grid>
                    </Grid>
                </Stack>
            </Card>
        )
}

export default GameItem