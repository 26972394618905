import { ArrowBack, ArrowForward, FirstPage, LastPage } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import React from 'react'

const PageKeeper = ({page, onChange, totalPages}) => {
  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <IconButton disabled={!page>0} onClick={() => onChange(0)}>
          <FirstPage fontSize='large'/>
        </IconButton>
        <IconButton disabled={!page>0} onClick={() => onChange(page-1)}>
          <ArrowBack fontSize='large'/>
        </IconButton>
        <Typography variant='h6' fontFamily={'Acme'} sx={{mx:5}}>{`${page+1} of ${totalPages}`}</Typography>
        <IconButton disabled={page>=(totalPages-1)} onClick={() => onChange(page+1)}>
          <ArrowForward fontSize='large'/>
        </IconButton>
        <IconButton disabled={page>=totalPages-1} onClick={() => onChange(totalPages-1)}>
          <LastPage fontSize='large'/>
        </IconButton>
      </Stack>
  )
}

export default PageKeeper