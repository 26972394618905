import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../auth/authSlice'
import { useVerifyEmailMutation } from './usersApiSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const effectRan = useRef(false);
    const verifyToken = searchParams.get('token');
    const token = useSelector(selectCurrentToken);
    const [contentMessage, setContentMessage] = useState('Loading...');
    const [timeLeft, setTimeLeft] = useState(10);
    const navigate = useNavigate();

    const [verifyEmail,{
        isSuccess: isVerifySuccess,
        isLoading: isVerifyLoading,
        isError: isVerifyError,
        data: verifyData,
        error: verifyError
    }] = useVerifyEmailMutation();

    useEffect(() => {
        if(isVerifySuccess){
            console.log('Succeded');
            setTimeLeft(0);
        }
    },[isVerifySuccess])

    useEffect(() => {
        if(isVerifyError){
            setContentMessage(verifyError?.data?.message);
            console.log('Failed');
        }
    },[isVerifyError])


    useEffect(() => {
    if(effectRan.current === false){
        console.log('verifying!');
        verifyEmail({token: verifyToken})
    }
        return () => {effectRan.current = true};
    }, [])
    
    useEffect(()=>{
        if(timeLeft <= 0) navigate('/login');
        const intervalId = setInterval(()=>{
            setTimeLeft(timeLeft-1);
        },1000);
        return () => clearInterval(intervalId);
    },[timeLeft]);

  return (
    <>
    <Typography fontFamily={'Acme'} variant='h5' color={isVerifyError ? 'error' : 'white'} align='center' sx={{m:2}}>{contentMessage}</Typography>
    <Typography fontFamily={'Acme'} variant='body2' align='center' sx={{m:2}}>Redirecting in {timeLeft}</Typography>
    </>
  )
}

export default VerifyEmail