import { AppBar, Box, Button, ButtonGroup, Container, Drawer, IconButton, Link, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../images/logo192.png';


const BannerLoggedOut = () => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const navigate = useNavigate();
    //const { pathname } = useLocation();
    const handleSignupOpen = () => navigate('/signup');

    const onGamesClicked = () => navigate('/games');
    const onGoRootClicked = () => navigate('/');

    const pages = [
        { title: 'About', action: () => navigate('/about') },
        { title: 'Finished Games', action: () => navigate('/games') },
    ];

    const content = (
        <header>
            <AppBar position="fixed" >
                <Container maxWidth='xl'>
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton size="large" onClick={(e) => setMenuOpen(true)/*setAnchorEl(e.currentTarget)*/}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor='left' open={menuOpen} onClose={() => setMenuOpen(false)} sx={{ zIndex: 1302 }}>
                                {pages.map((p) => (
                                    <MenuItem key={p.title} onClick={() => { p.action(); setMenuOpen(false); }}>
                                        <Typography textAlign={'center'} fontFamily={'Acme'}>{p.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Drawer>
                        </Box>
                        <Button onClick={() => navigate('/')} title="home" disableRipple disableElevation sx={{ "&:hover": { backgroundColor: 'transparent' }, mx: 0.5, display: { xs: 'flex', sm: 'none' } }}>
                            <img src={logo} style={{ width: 'auto', height: 48 }} aria-label="ArtRush" />
                        </Button>
                        <Button onClick={() => navigate('/')} title="home" disableRipple disableElevation sx={{ "&:hover": { backgroundColor: 'transparent' }, mx: 1, display: { xs: 'none', sm: 'flex' } }}>
                            <img src={logo} style={{ width: 'auto', height: 64 }} aria-label="ArtRush" />
                        </Button>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <Button>
                                <Typography variant="h6" component="div" onClick={() => { navigate('/about') }} fontFamily={'Acme'}>
                                    About
                                </Typography>
                            </Button>
                            <Button>
                                <Typography variant="h6" component="div" onClick={() => { navigate('/games') }} fontFamily={'Acme'}>
                                    Finished Games
                                </Typography>
                            </Button>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <ButtonGroup>
                            <Button variant="contained" color="info" >
                                <Typography variant="h6" color="White" fontWeight="700" onClick={handleSignupOpen} fontFamily={'Acme'}>
                                    Sign Up
                                </Typography>
                            </Button>
                            <Button color="secondary">
                                <Typography variant="h6" color="White" fontWeight="700" onClick={() => navigate('/login')} fontFamily={'Acme'}>
                                    Log In
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </Toolbar>
                </Container>
            </AppBar>
        </header>
    );
    return content;
}

export default BannerLoggedOut