import React, { useEffect } from 'react'
import { useResetPasswordMutation } from './authApiSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, List, ListItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ArrowRight, Send } from '@mui/icons-material';

const Reset = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const token = searchParams.get('token');
    const user = searchParams.get('user')
    const [username, setUsername] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [validPassword, setValidPassword] = React.useState(false);
    const [retypePassword, setRetypePassword] = React.useState('');
    const [validRetypePassword, setValidRetypePassword] = React.useState(false);

    useEffect(() => {
        if(user)
            setUsername(user);
    },[user]);

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(newPassword))
    }, [newPassword]);

    useEffect(() => {
        setValidRetypePassword(newPassword === retypePassword)
    }, [newPassword, retypePassword]);

    //Between 8 and 20 characters long
    //Contains at least one lowercase letter
    //Contains at least one uppercase letter
    //Contains at least one number
    //Contains at least one special character
    const PWD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?-])(?=.*[^\da-zA-Z]).{8,30}$/

    const [errorText, setErrorText] = React.useState('');

    const [resetPassword, {
        data: varifydata,
        isSuccess: isResetSuccess,
        isError: isResetError,
        isLoading: isResetLoading,
        error: resetError
    }] = useResetPasswordMutation();

    useEffect(() => {
        if(isResetError){
            setErrorText(resetError.data.message);
        }
    }, [isResetError])

    useEffect(() => {
        if(!token.length){
            navigate('/');
        }
    })

    useEffect(() => {
        if (isResetSuccess) {
            navigate('/login');
        }
    }, [isResetSuccess]);

    const onSubmit = () => {
        resetPassword({ token, username, newPassword });
    }

    const isPasswordError = Boolean(newPassword.length && !validPassword);
    const isRetypePasswordError = !validRetypePassword;

    const canSubmit = username.length && validPassword && validRetypePassword;

    const StyledListItem = ({ children }) => (
        <ListItem style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
          <ArrowRight sx={{ paddingRight: "4px" }} />
          <Typography variant="caption">{children}</Typography>
        </ListItem>
      );

    return (
        <form>
            <Stack alignItems={'center'} justifyContent={'center'} spacing={2}>
                <Typography variant='h3' align='center'>Reset Password</Typography>
                <TextField
                    label="Username"
                    disabled={user}
                    onChange={(e) => setUsername(e.target.value)}
                    inputProps={{ maxLength: 16 }}
                    variant="standard"
                    autoComplete="username"
                    value={username}
                />
                <Tooltip arrow placement="bottom" title={
                    <>
                        <Typography variant="subtitle2">Password must contain:</Typography>
                        <List>
                            <StyledListItem>Between 8 and 30 characters</StyledListItem>
                            <StyledListItem>At least one lowercase letter</StyledListItem>
                            <StyledListItem>At least one uppercase letter</StyledListItem>
                            <StyledListItem>At least one number</StyledListItem>
                            <StyledListItem>At least one special character</StyledListItem>
                        </List>
                    </>
                }>
                    <TextField
                        label="New Password"
                        type="password"
                        error={isPasswordError}
                        onChange={(e) => setNewPassword(e.target.value)}
                        helperText={isPasswordError ? 'Invalid password' : ''}
                        variant="standard"
                        autoComplete="new-password"
                        value={newPassword}
                    />
                </Tooltip>
                <TextField
                    label="Confirm Password"
                    type="password"
                    error={isRetypePasswordError}
                    onChange={(e) => setRetypePassword(e.target.value)}
                    helperText={isRetypePasswordError ? 'Passwords must match!' : ''}
                    variant="standard"
                    autoComplete="new-password"
                    value={retypePassword}
                />
                <Button disabled={!canSubmit} onClick={onSubmit} endIcon={<Send />} variant="outlined">Submit</Button>
                <Typography variant='caption' color={'red'}>{errorText}</Typography>
            </Stack>
        </form>
    )
}

export default Reset