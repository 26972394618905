const emotes = [
    {value:"🙂",key:"Smile"},
    {value:"😂",key:"Laugh"},
    {value:"😲",key:"Wow"},
    {value:"🤨",key:"Concerned"},
    {value:"😐",key:"Neutral"},
    {value:"💀",key:"Skull"},
    {value:"\u2764",key:"Heart"}, //❤
];

export default emotes;