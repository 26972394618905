import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFollowUserMutation, useGetUserQuery, useUpdateUserMutation } from './usersApiSlice';
import { Avatar, Button, Grid, IconButton, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { selectCurrentUser } from '../auth/currentUserSlice';
import { useSelector } from 'react-redux';
import { useLazyGetPanelsQuery } from '../games/gamesApiSlice';
import PanelSample from '../../components/Home/PanelSample';
import { ArrowBack, ArrowForward, Edit, FirstPage, LastPage } from '@mui/icons-material';
import emotes from '../panels/Emotes';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import PageKeeper from '../../components/PageKeeper';


const UserView = () => {
  let { username } = useParams();
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
    error: userError
  } = useGetUserQuery(username);
  const [updateUser, {
    data: updateduser,
    isSuccess: isUpdateUserSuccess,
    isError: isUpdateUserError,
    isLoading: isUpdateUserLoading,
    error: updateuserError
  }] = useUpdateUserMutation();

  const [getPanels, {
    data: panels,
    isLoading: isPanelsLoading,
    isSuccess: isPanelsSuccess,
    isError: isPanelsError,
    error: panelsError
  }] = useLazyGetPanelsQuery();
  const [followUser, {
    data: follow,
    isSuccess: isFollowSuccess,
    isError: isFollowError,
    isLoading: isFollowLoading,
    error: followError
  }] = useFollowUserMutation();

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(8);
  const [total, setTotal] = React.useState(0);
  const [pronouns, setPronouns] = React.useState('');
  const [userLocation, setUserLocation] = React.useState('');
  const [isSaved, setIsSaved] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const currUser = useSelector(selectCurrentUser);
  const [isFollowing, setIsFollowing] = React.useState(false);
  useEffect(() => {
    if (isUserSuccess) {
      getPanels({ usersArray: [user._id], count, page })
      setPronouns(user.profile.pronouns);
      setUserLocation(user.profile.location);
      if (currUser)
        setIsFollowing(user.profile.followers.map(x => x.toString()).includes(currUser._id.toString()));
    }
  }, [currUser, isUserSuccess, page, location]);
  useEffect(() => {
    if (isFollowSuccess)
      setIsFollowing(follow.following)
  })
  useEffect(() => {
    if (isPanelsSuccess)
      setTotal(panels.total)
  }, [isPanelsSuccess])

  useEffect(() => {
    if (isUpdateUserSuccess) setIsSaved(true);
  }, [isUpdateUserSuccess])
  const onFollowUser = async () => {
    followUser({ unfollow: isFollowing, userId: user._id });
  }

  const onSaveUserClicked = () => {
    const userData = { profile: { pronouns: pronouns, location: userLocation } };
    const userId = user._id;
    console.log(userData);
    updateUser({ userId, userData });
  }

  const profile =
    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{p:1}}>
      <Grid item container direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
        <Grid item>
          {!isUserSuccess ?
            <Skeleton variant='circular' height={192} width={192} /> :
            <Avatar src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${user.profile.avatar}.png`} sx={{ height: 192, width: 192 }} />
          }

        </Grid>
        <Grid item>
          <Stack spacing={1}>
            {!isUserSuccess ?
              [...Array(4)].map((x, i, j) => <Skeleton key={i} variant='rounded' height={32} width={128} />) :
              <>
                <Typography variant='h4' fontFamily={'Acme'} align='center'>{user.username}</Typography>
                <Typography variant='subtitle1' fontFamily={'Acme'} align='center'>Level {user.profile.rank}</Typography>
                {currUser?._id !== user._id ?
                  <>
                    <Typography variant='body1' fontFamily='Acme' sx={{ opacity: 0.5 }} align='center'>
                      {user.profile.pronouns ?? <i>No pronouns specified</i>}
                    </Typography>
                    <Typography variant='body1' fontFamily='Acme' sx={{ opacity: 0.5 }} align='center'>
                      {user.profile.location ?? <i>No location specified</i>}
                    </Typography>
                    <Button variant='contained' color={isFollowing ? 'error' : 'success'} onClick={onFollowUser}>
                      <Typography variant='body1' fontFamily='Acme' fontWeight={700} color={'white'}>{!isFollowing ? 'Follow' : 'Unfollow'}</Typography>
                    </Button>
                  </>
                  :
                  <>
                    <TextField variant='standard' helperText={"Enter your pronouns"} onChange={e => { setIsSaved(false); setPronouns(e.target.value) }} value={pronouns} />
                    <TextField variant='standard' helperText={"Enter your location"} onChange={e => { setIsSaved(false); setUserLocation(e.target.value) }} value={userLocation} />

                    <Button disabled={isUpdateUserLoading}
                      onClick={onSaveUserClicked}
                      color={isSaved ? 'success' : 'primary'}
                      variant='contained'>
                      <Typography fontFamily={'Acme'}>{isSaved ? "Saved" : "Save"}</Typography>
                    </Button>
                  </>}
              </>

            }
          </Stack>
        </Grid>
      </Grid>
      {/* 
      <Grid item sx={{ m: 2 }}>
        {!isUserSuccess ?
          <Skeleton variant='text' width={512} /> :
          <Typography variant='body1' fontFamily='Acme' sx={{ opacity: 0.5 }} align='center'>
            {user.profile.bio ?? <i>No bio... yet</i>}
          </Typography>
        }
      </Grid>
      */}
    </Grid>

  const emoteStats =
    <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-evenly'}>
      <hr style={{ width: '90%', opacity: '25%' }} />
      <Grid item>
      <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
        <Typography variant='h5' fontFamily={'Acme'} align='center'>
          {isUserSuccess ? Object.entries(user.emotes).map(([key, value], i) => value).reduce((a, b) => a + b, 0) : ''} Earned Emotes
        </Typography>
        <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
          {emotes.map(e => (
            <Stack key={e.key} direction={'column'}>
              <Typography variant='h5' fontFamily={'Noto Emoji'} align='center' sx={{ userSelect: 'none' }}>
                {e.value}
              </Typography>
              <Typography variant='h6' fontFamily={'Acme'} align='center'>
                {isUserSuccess ? user.emotes[e.key] : <Skeleton variant='text' />}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack direction={'row'}>

        </Stack>
      </Stack>
      </Grid>
      <Grid item>
      <Stack spacing={2}>
        <Typography variant='h5' fontFamily={'Acme'} align='center' sx={{ userSelect: 'none' }}>Trophy Case</Typography>
        <Stack direction={'row'} spacing={2}>
          {/*put trophies here when implemented*/}
          <Typography variant='h6' fontFamily={'Acme'} align='center' sx={{ opacity: 0.5 }}><i>Looks like there's nothing here yet!</i></Typography>
        </Stack>
      </Stack>
      </Grid>
      <hr style={{ width: '90%', opacity: '25%' }} />
    </Grid>

  const games =
    <Grid container alignItems={'center'} justifyContent={'center'}>
      {!isPanelsSuccess ?
        Array(15).map((i, j, k) => { return <Skeleton key={j} variant='rectangular' height={256} width={256} sx={{ m: 1 }} /> }) :
        panels?.panels?.map((i, j, k) => { return <PanelSample key={j} showAvatar={false} panel={i} onClick={() => navigate(`/games/id/${i.game._id}`)} sx={{ m: 1 }} /> })
      }
    </Grid>
  const content =
    <Stack direction={'column'} alignContent={'center'} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}  spacing={2} sx={{mt:1}}>
      {profile}
      {emoteStats}
      {games}

      <PageKeeper page={page} onChange={setPage} totalPages={Math.ceil(total / count)}/>
    </Stack>
  return content;
}


export default UserView