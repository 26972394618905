import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";


const palettesAdapter = createEntityAdapter({});
const initialState = palettesAdapter.getInitialState();

export const paletteApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllPalettes: builder.query({
            query: () => '/palette',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedPalettes = responseData.map(palette => {
                    palette.id = palette._id;
                    return palette;
                });
                return palettesAdapter.setAll(initialState, loadedPalettes);
            },
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        {type: 'Palette', id: 'LIST'},
                        ...result.ids.map(id => ({type: 'Palette', id}))
                    ];
                } else return [{type:'Palette', id:'LIST'}];
            }
        }),
        getPalette: builder.query({
            query: (id) => `/palette/${id}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
        }),
        createNewPalette: builder.mutation({
            query: initialPaletteData => ({
                url:'/palette',
                method: 'POST',
                body: {...initialPaletteData}
            }),
            invalidatesTags: [
                {type: 'Palette', id:"LIST"}
            ],
            providesTags: (result, error, arg) => [
                {type: 'Palette', id: result.id}
            ]
        }),     
    })
});

export const {
    useGetAllPalettesQuery,
    useGetPaletteQuery,
    useCreateNewPaletteMutation
} = paletteApiSlice;

export const selectPalettesResult = paletteApiSlice.endpoints.getAllPalettes.select();

const selectPalettesData = createSelector(
    selectPalettesResult,
    palettesResult => palettesResult.data
)

export const {
    selectAll: selectAllPalettes,
    selectById: selectPaletteById,
    selectIds: selectPaletteIds
} = palettesAdapter.getSelectors(state => selectPalettesData(state) ?? initialState);