import { AppBar, Button, Container, Stack, Toolbar, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../auth/currentUserSlice'
import Footer from '../../components/Banner/Footer'
import logo from '../../images/logo192.png'

const ModDashboard = () => {
    const dashboards = ['Games', 'Users', 'Panels', 'Invites']
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    useEffect( () => {
        if (user && ["admin", "moderator"].indexOf(user.role) < 0) {
        navigate('/');}
    },[user])

    
    return (
        <>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters>
                        <Stack direction={'row'} spacing={2}>
                        <Button onClick={() => navigate('/home')} title="home" disableRipple disableElevation sx={{ "&:hover": { backgroundColor: 'transparent' }, mx: 1 }}>
                            <img src={logo} style={{ width: 'auto', height: 64 }} aria-label="ArtRush" />
                        </Button>
                        {dashboards.map(d => <Button onClick={() => navigate(`/dashboard/${d}`)}><Typography variant='h6' fontFamily={'Acme'}>{d}</Typography></Button>)}
                        </Stack>
                        </Toolbar>
                </Container>
            <Outlet />

        </>
    )
}

export default ModDashboard