import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPaletteQuery } from '../../palette/paletteApiSlice';
import { selectActiveGame } from '../gameSlice';
import { useSkipGameMutation, useSubmitGameMutation } from '../gamesApiSlice';
import CaptionView from './CaptionView';
import DrawView from './DrawView';

const Play = (props) => {
    const activeGame = useSelector(selectActiveGame);
    //console.log(activeGame);
    const [game, setGame] = React.useState(activeGame);
    //console.log(game);
    const navigate = useNavigate();

    const onSubmitGame = async (content, panelType) => {
        await submitGame({content:content, id:game._id, panelType});
    }

    const onSkipGame = async () => {
        await skipGame({id:game._id})
    }

    let content = "Play";
        const {
            data: palette,
            isLoading,
            isSuccess,
            isError,
            error
        } = useGetPaletteQuery(game?.palette);
        const [submitGame, {
            data: gameData,
            isLoading: isGameSubmitLoading,
            isSuccess: isGameSubmitSuccess,
            isError: isGameSubmitError,
            error: gameError
        }] = useSubmitGameMutation();
        const [skipGame, {
            isLoading: isSkipLoading,
            isSuccess: isSkipSuccess,
            isError: isSkipError,
            error:  skipError
        }] = useSkipGameMutation();


        useEffect(() => {
            if(isGameSubmitSuccess)
                props.newGame(gameData._id, gameData.content, gameData.panelType, gameData.completed);
            else if(isSkipSuccess)
                props.newGame(null, null, false)
        },[isGameSubmitSuccess, isSkipSuccess]);
        
        let firstPanel;
    
        if(isLoading)
        content = 
        <Grid container direction={'row'} justifyItems={'center'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            <Card raised>
                <Typography variant='h4' fontFamily={'Acme'}>"Loading Palette..."</Typography>
            </Card>
        </Grid>
        
        if(isError)
        content =
        <Grid container direction={'row'} justifyItems={'center'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            <Card raised>
                <Typography variant='h4' fontFamily={'Acme'}>{error?.data?.message}</Typography>
            </Card>
        </Grid>
    
        if(isSuccess){
            if(!game) return <Card raised><Typography>Game was improperly loaded</Typography></Card>
            if (game.panel) {
                switch (game.panel.panelType) {
                    case "drawing":
                        firstPanel = <img style={{height:256, width:"auto", objectFit:"contain"}} src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${game.panel.content}.png`} alt='Previous Drawing' />
                        break;
                    case "caption":
                        firstPanel =
                            <Typography align='center' fontFamily={'Acme'} variant="h4" sx={{ wordBreak: "break-word", m: 1, alignSelf:'center' }}>{game.panel.content}</Typography>
                        break;
                }
            } else firstPanel = <Typography align='center' fontFamily={'Acme'} variant="h4" sx={{ whiteSpace: 'nowrap', overflowWrap: 'break-word', m: 1, alignSelf:'center' }}>New Game</Typography>

            content = game.nextPanel === 'caption' ?  
            <CaptionView submitCanvas={onSubmitGame} skipGame={onSkipGame} close={props.exitGame} lastPanel={firstPanel} sx={{m:1}}/> : 
            <DrawView activePalette={palette} submitCanvas={onSubmitGame} skipGame={onSkipGame} lastPanel={firstPanel} close={props.exitGame} sx={{m:1}}/>
        }
    
    return content
}

export default Play