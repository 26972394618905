import { apiSlice } from "../../app/api/apiSlice";
import { setCurrUser, removeCurrUser } from "./currentUserSlice";


export const currentUserApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCurrUser: builder.query({
            query: () => '/users/current',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 5,
        }),
        getCurrUserProfile: builder.query({
            query: () => '/users/profile',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 5,
        }) 
    })
});

export const {
    useGetCurrUserQuery,
    useLazyGetCurrUserQuery,
    useGetCurrUserProfileQuery
} = currentUserApiSlice;