import { store } from '../../app/store'
import { notificationsApiSlice } from '../notifications/notificationsApiSlice';
import { paletteApiSlice } from '../palette/paletteApiSlice';
import { usersApiSlice } from '../users/usersApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {
    useEffect(() => {
        //console.log('subscribing')
        //const notifications = store.dispatch(notificationsApiSlice.endpoints.getNotifications.initiate());
        const palettes = store.dispatch(paletteApiSlice.endpoints.getAllPalettes.initiate());
        return () => {
            //console.log('unsubscribing')
            //notifications.unsubscribe();
            palettes.unsubscribe();
        }
    }, [])

    return <Outlet />
}

export default Prefetch