import React from 'react'

const AdSenseAd = ({client = "ca-pub-8211804556258538", slot, responsive=true}) => {
    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8211804556258538"
                crossOrigin="anonymous"></script>
            {/*!-- main - page - footer--*/}
            <ins className="adsbygoogle"
                style={{display:"block"}}
                data-ad-client={client}
                data-ad-slot={slot}
                data-ad-format="auto"
                data-full-width-responsive={responsive}></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
            </script>
        </>

    )
}

export default AdSenseAd