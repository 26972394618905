import React, { useEffect } from 'react'
import { useGenerateInvitesMutation, useLazyGetInvitesQuery } from './moderatorApiSlice'
import { Button, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const InvitesDashboard = () => {
  const [getInvites, {
    isSuccess: isGetInvitesSuccess,
    isLoading: isGetInvitesLoading,
    isError: isGetInvitesError,
    data: getInvitesData,
    error: getInvitesError
  }] = useLazyGetInvitesQuery();

  const [generateInvites, {
    isSuccess: isGenerateInvitesSuccess,
    isLoading: isGenerateInvitesLoading,
    isError: isGenerateInvitesError,
    data: generateInvitesData,
    error: generateInvitesError
  }] = useGenerateInvitesMutation();

  useEffect(() => {
    getInvites();
  },[]);

  useEffect(() => {
    setCreateCount(0);
    getInvites();
  }, [isGenerateInvitesSuccess]);

  const [createCount, setCreateCount] = React.useState(0)
  const onCreateCountChanged = (e) => { setCreateCount(Math.max(0, e.target.value)) };
  const onCreateInvitesClicked = () => {
    if (createCount <= 0) return;
    generateInvites(createCount);
    setCreateCount(0);
  };

  const columns = [
    {field:'dateCreated', headerName:'Created', width:150},
    {field:'code', headerName:'Invite Code', width:150},
    {field:'user', headerName:'Used By', width:150},
    {field:'createdBy', headerName:'Creator', width:150},
  ];
  let rows = [];
  if(isGetInvitesSuccess){
    rows = getInvitesData?.invites.map( i => ({id:i._id, code:i.code, user: i.user?.username, createdBy:i.createdBy?.username, dateCreated:new Date(i.dateCreated).toLocaleString()}));
  }
  return (
    <Stack direction={'column'} spacing={2}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
        <Typography>Enter number of invite codes to create:</Typography>
        <TextField value={createCount} type='number' onChange={onCreateCountChanged} />
        <Button onClick={onCreateInvitesClicked}>Sumbit</Button>
      </Stack>
      <DataGrid rows={rows} columns={columns}/>
    </Stack>

  )
}

export default InvitesDashboard