import { Stack, Typography } from '@mui/material'
import React from 'react'

const Shop = () => {
  return (
    <Stack sx={{m:2}} spacing={1}>
      <Typography variant='h3' fontFamily={'Acme'}>The Crayon store isn't open yet!</Typography>
      <Typography variant='h6' fontFamily={'Acme'}>Please check back soon as we bring all the site functionality online. Our offerings will include new color palettes, game modes, and more!</Typography>
    </Stack>
  )
}

export default Shop