import { Avatar, Card, IconButton, Stack, Tooltip } from '@mui/material'
import React from 'react'
import AdSenseAd from './AdSenseAd'

const PanelSampleAd = ({ showAvatar = true, slot }) => {
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
            {showAvatar ? 
            <Tooltip title="Advertisement" arrow>
                <IconButton sx={{ mr: 1 }}>
                <Avatar alt="Ad" sx={{ height: 64, width: 64}}>Ad</Avatar>
                </IconButton>
            </Tooltip> : null}
            <Card sx={{height:256, width:256}}>
                <AdSenseAd slot={slot}/>
            </Card> 
        </Stack>
    )
}

export default PanelSampleAd