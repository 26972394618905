import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useLazyGetGamesModeratorQuery } from './moderatorApiSlice';
import { useSearchParams } from 'react-router-dom';

const GamesDashboard = () => {
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(100);
    const [getGamesModerator,{
        data:games,
        isSuccess:isGameSuccess,
        isError:isGamesError,
        isLoading:isGamesLoading,
        error:gamesError
    }] = useLazyGetGamesModeratorQuery();

    useEffect(() => {
        getGamesModerator({page,count});
    },[page,count])

    const columns = [
        {field: 'id', headerName: 'ID', width:250},
        {field: 'active', headerName:'Active',width:75},
        {field: 'creator', headerName:'Created By',width:150},
        {field: 'lastUpdated', headerName:' Last Updated',width:200,},
        {field: 'gameMode', headerName:'Mode',width:150},
        {field: 'lastPanel', headerName:'Last',width:75},
        {field: 'nextPanel', headerName:'Next',width:75},
        {field: 'gameLength', headerName:'Length',width:75},
        {field: 'timeLimit', headerName:'Time',width:50},
        {field: 'completed', headerName:'Completed',width:100},
        {field: 'skips', headerName:'Skips',width:75},
        
    ]
    //define params and populate from URL
    const perPage = 100;
    const [searchParams] = useSearchParams();
    const params = {id:null, page:null, gameType:null, createdBy:null, createdAt:null, lastUpdated:null, gameLength:null, timeLimit:null, completed:null}
    for(const [key, value] of Object.entries(params)){
        params[key] = searchParams.get(key)
    }
    let content = 'Loading...'
    if(isGameSuccess){
        const rows = games.map(g => ({...g,
            lastUpdated:new Date(g.lastUpdated).toLocaleString(),
            id:g._id,
            skips:g.skips.length,
            creator:g.creator?.username,
            panels:g.panels.length,
            lastPanel:g.panels.at(-1).panelType,
            timeLimit: `${g.timeLimit/60000}m`}))
        content = <DataGrid rows={rows} columns={columns}/>
    }
    
    return content;
}

export default GamesDashboard