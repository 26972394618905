import { Box, Card, Grid, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

const ProgressBar = ({ sx, xp, currentLevel, nextXp, currentXp, tokens }) => {
    const percentage = xp > nextXp ? 100 : Math.floor((xp - currentXp) / (nextXp - currentXp) * 100);
    return (
        <Box flexGrow={1} sx={{ ...sx, }}>
            <Card raised>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant='subtitle2' fontFamily={'Acme, Noto Color Emoji'}>🖍️{tokens}</Typography>
                    <Typography variant='subtitle2' fontFamily={'Acme'} sx={{ ml: 2, width: 'fit-content' }}>Level {currentLevel}</Typography>
                    <Stack direction={'row'} sx={{ width: '33%' }} alignItems={'center'} justifyContent={'center'}>
                        <div style={{ backgroundColor: 'lime', width: `${percentage}%`, height: '.25rem' }} />
                        <div style={{ backgroundColor: 'green', width: `${100 - percentage}%`, height: '.25rem' }} />
                    </Stack>
                    <Typography variant='subtitle2' fontFamily={'Acme'}>{xp}/{nextXp} xp</Typography>
                </Stack>
            </Card>
        </Box>
    )
}

export default ProgressBar