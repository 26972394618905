import { ArrowDownward } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import React from 'react'

const About = () => {
  return (
    <Stack sx={{m:2}} spacing={4} alignItems={'center'}>
        <Typography variant='h3' fontFamily={'Acme'}>
            About ArtRush™
        </Typography>
        <Typography variant='h6' fontFamily={'Acme'} align='center'>
        ArtRush™ is a free, multiplayer, online game full of of mischievous miscommunication and collaborative creativity where doodling meets Broken Telephone<br/>
        Get creative drawing and captioning or compete against artists in more competitive game modes. There is a game out there for everyone and the most important part is to have fun!
        </Typography>
        <Stack>
        <Accordion>
            <AccordionSummary id='faq' aria-controls='faq' expandIcon={<ArrowDownward/>}>
                <Typography fontFamily={'Acme'} variant='h4'>FAQ:</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Stack spacing={2}>
                    <Typography fontFamily={'Acme'} variant='h6'>
                        <strong>Q:</strong> Is ArtRush™ free?<br/>
                        <strong>A:</strong> Yes! ArtRush™ is completely free to play. We plan on adding paid content in the future but will never put the game behind a paywall
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                        <strong>Q:</strong> Do I need to be an artist to play?<br/>
                        <strong>A:</strong> No! ArtRush is a game where the goal is to have fun. No drawing skills required as long as you give it your best
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                        <strong>Q:</strong> What am I allowed to draw?<br/>
                        <strong>A:</strong> Anything you want! (As long as it follows the rules below and doesn't contain any graphic or lewd content)
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                        <strong>Q:</strong> Is there an ArtRush™ mobile app?<br/>
                        <strong>A:</strong> Not yet!
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                        <strong>Q:</strong> Who created the ArtRush™ website?<br/>
                        <strong>A:</strong> ArtRush™ was designed and created by <a href='/user/oleusi' style={{color:'plum', textDecoration:'none'}}>oleusi</a>
                    </Typography>
            </Stack>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary id='rules' aria-controls='rules' expandIcon={<ArrowDownward/>}>
                <Typography fontFamily={'Acme'} variant='h4'>Rules:</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={4}>
                    <Typography fontFamily={'Acme'} variant='h6'>Rule 1.<br/>Public games should be considered PG-13 so do not draw or type anything too graphic. Hateful or threatening content is never allowed, even in private games</Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>Rule 2.<br/>Do not inject any characters or franchises into a game that were not already there and do your best to describe or draw the content in the previous panel. <br /><strong>Please do not derail any games!</strong></Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>Rule 3.<br/>Witch hunting and brigading will not be tolerated. If you encounter a problematic user please report them to the moderation team</Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>Rule 4.<br/>Not every game mode is a competition and there's no need to be an artist to have fun!</Typography>
                </Stack>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary id='modes' aria-controls='modes' expandIcon={<ArrowDownward/>}>
                <Typography fontFamily={'Acme'} variant='h4'>Game Modes:</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={4}>
                    <Typography fontFamily={'Acme'} variant='h6'>There are currently 4 available ArtRush™ game modes:</Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                        <strong>Miscommunication:</strong><br/>
                        This is a good old game of Broken Telephone but with drawing what you read and describing what you see
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                    <strong>Imitation:</strong><br/>
                        They say immitation is the greatest form of flattery. In this game mode you try to redraw the previous image from the last panel
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                    <strong>Competition:</strong><br/>
                        Dust off those graphics tablets! In this game mode everybody gets the same caption and players can vote on whose drawing they like best
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                    <strong>Caption Contest:</strong><br/>
                        This is like a competition done backwards. Everybody gets the same image and the best or wittiest description (as chosen by players) wins! 
                    </Typography>
                </Stack>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary id='xp' aria-controls='xp' expandIcon={<ArrowDownward/>}>
                <Typography fontFamily={'Acme'} variant='h4'>Game Mechanics:</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={4}>
                    <Typography fontFamily={'Acme'} variant='h6'>Outside of the basic drawing and captioning you can earn XP as you play, causing you to level up and earn Crayons (🖍️), the in-game currency. Crayons will allow you start new games, purchase new color pallettes, unlock new features, and more!</Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>Here are the following ways to earn XP:</Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                    <strong>Playing the Game</strong><br/>
                        The more you play, the more XP you earn! You get XP for submitting captions and drawings (and just a <i>little</i> bit more if you're the one to complete a game)
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                    <strong>Getting Emotes</strong><br/>
                        The more players who react to your panel the more XP you get! All the more reason to make something noteworthy
                    </Typography>
                    <Typography fontFamily={'Acme'} variant='h6'>
                    <strong>Top Games</strong><br/>
                        Make sure to click the '♡' symbol below any games you like. Every week a set of top games will be chosen. These are based on how many times a game gets favorited and are player selected. Players in top games get a big XP bonus!
                    </Typography>
                </Stack>
            </AccordionDetails>
        </Accordion>
        </Stack>
    </Stack>
  )
}

export default About