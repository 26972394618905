import { Circle, Square } from '@mui/icons-material'
import { Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectPaletteById } from './paletteApiSlice'

const Palette = ({paletteId}) => {
    const palette = useSelector(state => selectPaletteById(state, paletteId));
    console.log(palette);
    const [name, setName] = React.useState('');
    const [values, setValues] = React.useState([]);
    useEffect(() => {
        if(palette){
            //console.log(palette);
            setName(palette.name);
            setValues(palette.values);
        }
    },[palette])

    //const colors = values?.length ? values.map() : null;
    const content = 
    <Grid container direction={'column'}>
        <Grid item>
            <Typography variant='h6' fontFamily={'Acme'}>{name}</Typography>
        </Grid>
        <Grid item>
            <Grid container direction={'row'}>
                {palette ? 
                values.map(color => <Circle key={color} sx={{color:color, outlineColor:'black', outlineWidth:'thin'}}/>) : 
                Array(16).map((i,j,k) => {return <Skeleton key={j} variant='circular' height={16} width={16}/>})}
            </Grid>
        </Grid>
    </Grid>
  return content;
}

export default Palette