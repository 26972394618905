import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const moderatorApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGamesModerator: builder.query({
            query: ({page, count}) => `/games/?page=${page}&count=${count}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
        }),
        getInvites: builder.query({
            query: () => '/auth/invite',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
        }),
        generateInvites: builder.mutation({
            query: (count) => ({
                url:'/auth/invite',
                method: 'PUT',
                body: {count}
            })
        })
    })
})

export const { 
    useGetGamesModeratorQuery, 
    useLazyGetGamesModeratorQuery,
    useGenerateInvitesMutation,
    useLazyGetInvitesQuery
} = moderatorApiSlice;