import { Instagram, Reddit } from '@mui/icons-material';
import { Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <Card sx={{position:'absolute',width:'100%', bottom:0, top:'auto', p:1, mt:'auto', zIndex:1301}} >
                <Typography variant='caption'>
                    {`© ${year} ArtRush. All rights reserved.`}<br/><a style={{color:"inherit"}} href='/PrivacyPolicy.txt'>Privacy Policy</a>, <a style={{color:"inherit"}} href='/TermsAndConditions.txt'>Terms & Conditions</a>, <a href='/contact' style={{color:"inherit"}}>Contact us</a><br/>
                </Typography>
    </Card>
  )
}

export default Footer