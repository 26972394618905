import { Avatar, Badge, Card, CardContent, CardMedia, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../../features/auth/currentUserSlice'
import { useEmotePanelMutation } from '../../features/games/gamesApiSlice'
import emotes from '../../features/panels/Emotes'

const PanelSample = ({ panel, showAvatar = true, gameId, onClick, sx, count, showLikes, onRefreshGame, responsive=false }) => {
    const navigate = useNavigate();
    const currUser = useSelector(selectCurrentUser);
    const [raised, setRaised] = React.useState(false);
    const [shadow, setShadow] = React.useState(1);
    const raise = () => {
        setRaised(true);
        setShadow(3);
    }
    const lower = () => {
        setRaised(false);
        setShadow(1);
    }

    const [emotePanel, {
        data: emoteData,
        isSuccess: isEmoteSuccess,
        isError: isEmoteError,
        isLoading: isEmoteLoading,
        error: emoteError
    }] = useEmotePanelMutation();

    useEffect(() => {
        if(isEmoteSuccess && currUser) onRefreshGame();
    }, [isEmoteSuccess, currUser])

    useEffect(() => {
        if(isEmoteError && emoteError.status === 401) navigate('/login');
    },[isEmoteError])
    const panelContent = panel.panelType === "drawing"
        ? <CardMedia component={'img'} xs={{height:'inherit'}} image={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${panel.content}.png`} />
        : <CardContent sx={{height:'100%', width:'100%', display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', justifyItems:'center'}}>
                <Typography fontFamily={'Acme'} variant="h4" align='center' sx={{ textAlign:'center', wordBreak: "break-word", m: 1, flexGrow:1, display:responsive ? {xs:'none', md:'flex'} : null }}>{panel.content}</Typography>
                { responsive ? 
                <Typography fontFamily={'Acme'} variant="h5" align='center' sx={{ textAlign:'center', wordBreak: "break-word", m: 1, flexGrow:1, display:{xs:'flex', md:'none'} }}>{panel.content}</Typography> : null
                }
        </CardContent>
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={sx}>
            {showAvatar ?
                <Tooltip title={panel.user.username} arrow>
                    <IconButton onClick={() => navigate(`/user/${panel.user.username}`)} sx={{ mr: 1 }}>
                        <Avatar alt={panel.user.username} src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${panel.user.profile.avatar}.png`} sx={{ height: 64, width: 64 }}>
                        </Avatar>
                    </IconButton>
                </Tooltip>
                : null}
            <Stack direction={'column'} sx={{ my: 1 }}>
                <Badge badgeContent={count ? <Typography variant='h6' fontFamily={'Acme'} fontWeight={700}>{count}</Typography> : null} color='primary' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ mt: 1, mb:0.5 }}>
                    <Tooltip title={new Date(panel.createdAt).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })} arrow>
                        <div>
                        <Card sx={{ flexGrow:1, height: 256, width: 256, cursor: onClick ? 'pointer' : 'auto', display: responsive ? {xs:'none', md:'flex'} : null}} onClick={onClick} onMouseOver={raise} onMouseOut={lower} raised={raised} elevation={shadow}>
                            {panelContent}
                        </Card>
                        {responsive ?
                        <Card sx={{flexGrow:1, height: 160, width: 160, cursor: onClick ? 'pointer' : 'auto', display:{xs:'flex', md:'none'}}} onClick={onClick} onMouseOver={raise} onMouseOut={lower} raised={raised} elevation={shadow}>
                            {panelContent}
                        </Card> : null
                        }
                        </div>
                    </Tooltip>
                </Badge>
                { showLikes ?
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                    {emotes.map((e) => {
                        const allEmotesByType = panel?.emotes?.filter(x => x.emote === e.key)
                        const emoteCount = allEmotesByType?.length;
                        const usersList = allEmotesByType?.map((x) => <Typography variant='body2' key={x.givenBy.username}>{x.givenBy.username}</Typography>)
                        if(usersList.length > 5){
                            usersList = [...usersList, <Typography variant='caption'>And {usersList.length} other{usersList.length-5 > 1 ? 's':''}</Typography>]
                        }
                        const includesCurrUser = allEmotesByType?.map((x) => x.givenBy._id).indexOf(currUser?._id.toString()) > -1;
                        return (
                            <Badge badgeContent={emoteCount ?? 0} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} key={e.key} color='default'>
                                <Tooltip title={usersList.length ? usersList : null}>
                                <a onClick={() => panel.user._id === currUser?._id ? null : emotePanel({emote:e.key,panel:panel._id})}>
                                    <Typography variant='h6' fontFamily={includesCurrUser ? 'Noto Color Emoji' : 'Noto Emoji'} align='left' sx={{userSelect:'none', cursor:'pointer'}}>
                                        {e.value}
                                    </Typography>
                                </a>
                                </Tooltip>
                            </Badge>
                        )
                    })}
                </Stack> : null}
            </Stack>

        </Stack>
    )
}

export default PanelSample