import React, { useEffect } from 'react'
import { useGetPlayableGameMutation } from '../gamesApiSlice'
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, Paper, Slider, Stack, TextField, Typography } from '@mui/material';
import { selectActiveGame } from '../gameSlice';
import { useSelector } from 'react-redux';
import { Circle, Delete, RadioButtonChecked, Redo, SkipNext, Undo } from '@mui/icons-material';
import { selectPaletteById } from '../../palette/paletteApiSlice';
import Timer from './Timer';

const CaptionView = (props) => {
    const [captionContent, setCaptionContent] = React.useState('');
    const activeGame = useSelector(selectActiveGame);

    const onCaptionChanged = (e) => { setCaptionContent(e.target.value.substr(0, 64)); }
    const submitCanvas = () => {
        props.submitCanvas(captionContent, "caption");
    }
    const skipGame = () => {
        props.skipGame();
    }

    return (
                <Stack direction={'column'} sx={{m:3}} spacing={2}>
                <Timer expires={activeGame.expires} skipGame={skipGame} submitGame={submitCanvas} lastPanel={props.lastPanel} close={props.close}>
                <Typography variant='h4' fontFamily={'Acme'} align='center'>Enter your caption:</Typography>
                <TextField value={captionContent} onChange={onCaptionChanged} />
                </Timer>
                </Stack>
    )
}

export default CaptionView