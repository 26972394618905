import { Add, Cancel, Logout, PlayArrow, Settings, Store } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Badge, Box, Button, ButtonGroup, Card, Container, Drawer, Fab, Grid, IconButton, Menu, MenuItem, Modal, Paper, Skeleton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetCurrUserQuery } from "../../features/auth/currentUserApiSlice";
import { setCurrentUser } from "../../features/auth/currentUserSlice";
import NewGame from "../../features/games/NewGame";
import Play from "../../features/games/PlayViews/Play";
import { removeActiveGame, setActiveGame } from "../../features/games/gameSlice";
import { useGetPlayableGameMutation } from "../../features/games/gamesApiSlice";
import logo from '../../images/logo192.png';
import logoFull from '../../images/logo.png'
import AvatarMenu from "./AvatarMenu";
import NotificationsDropdown from "./NotificationsDropdown";
import { useSendLogoutMutation } from "../../features/auth/authApiSlice";
import ProgressBar from "./ProgressBar";


const BannerLoggedIn = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [playModalOpen, setPlayModalOpen] = React.useState(false);
    const [newGameModalOpen, setNewGameModalOpen] = React.useState(false);
    const [completedGameModalOpen, setCompletedGameModalOpen] = React.useState(false);
    const [canPlay, setCanPlay] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [menuOpen, setMenuOpen] = React.useState(false);

    const [completedContent, setCompletedContent] = React.useState(null);
    const [completedPanelType, setCompletedPanelType] = React.useState('caption');
    const [completedGameComplete, setCompletedGameComplete] = React.useState(false);
    const [completedGameId, setCompletedGameId] = React.useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    //const { pathname } = useLocation();
    const onGoHomeClicked = () => navigate('/home');

    const [getPlayableGame, {
        data: game,
        isLoading: isGameLoading,
        isError: isGameError,
        error: gameError,
        isSuccess: isGameSuccess
    }] = useGetPlayableGameMutation();

    const [getCurrUser, {
        data: user,
        isLoading: isUserLoading,
        isSuccess: isUserSuccess,
        isError: isUserError,
        error: userError
    }] = useLazyGetCurrUserQuery();

    const [sendLogout, {
        isLoading: isLogoutLoading,
        isSuccess: isLogoutSuccess,
        isError: isLogoutError,
        error: logoutError
    }] = useSendLogoutMutation();

    useEffect(() => {
        getCurrUser();
    }, [location, newGameModalOpen, completedGameModalOpen]);

    useEffect(() => {
        if (isGameSuccess) {
            setErrorMessage('');
            dispatch(setActiveGame({ game }));
            setPlayModalOpen(true);
        }
    }, [isGameSuccess]);

    useEffect(() => {
        if (isGameError) {
            setPlayModalOpen(true);
            setErrorMessage(gameError?.data?.message);
            dispatch(removeActiveGame)
        }
    }, [isGameError]);

    useEffect(() => {
        if (isGameLoading) {
            setErrorMessage("Loading...");
        }
    }, [isGameLoading]);

    useEffect(() => {
        if (isUserError) {
            console.log(user.error);
        }
    }, [isUserError]);

    useEffect(() => {
        if (isUserSuccess) {
            setCanPlay(user.verifiedEmail);
            dispatch(setCurrentUser({ user }));
        }
    }, [isUserSuccess]);

    const onNewGameSubmit = async (e, gameId) => {
        newGameModalClose();
        onPlayClicked(e, gameId);
    }

    const logOut = () => {
        sendLogout();
        navigate('/');
    }

    const onPlayClicked = async (e, gameId) => {
        try {
            setPlayModalOpen(false);
            setCompletedGameModalOpen(false);
            setNewGameModalOpen(false);
            //console.log(`Getting game: ${gameId}`);
            await getPlayableGame(gameId ?? null);
        } catch (error) {
            console.log(error);
        }
    };

    const newGameModalClose = async () => {
        setNewGameModalOpen(false);
    }

    const playModalClose = () => {
        setPlayModalOpen(false);
    }

    const completedGameModalClose = () => {
        setCompletedGameModalOpen(false);
    }

    const completeGame = async (id, content, panelType, gameCompleted) => {
        setPlayModalOpen(false);
        dispatch(removeActiveGame);
        setCompletedContent(content);
        setCompletedGameComplete(gameCompleted);
        setCompletedPanelType(panelType);
        setCompletedGameId(id);
        setCompletedGameModalOpen(true);
    }

    const pages = [
        { title: 'Home', action: () => navigate('/home') },
        { title: 'Finished Games', action: () => navigate('/games') },
        { title: 'About', action: () => navigate('/about') },
        { title: 'Join the Discord', action: () => window.open('https://discord.gg/N7faCrMzmf', '_blank', 'rel=noopener noreferrer') },
    ];

    let usernameDisplay = '';
    let userAvatar = '';
    if (isUserLoading) usernameDisplay = 'Loading';
    let content = <Skeleton variant='text' />
    if (isUserSuccess) {
        usernameDisplay = user.username;
        userAvatar = user.profile.avatar;


        content = (
            <>
                <AppBar position="fixed" >
                    <Container maxWidth='xl'>
                        <Toolbar disableGutters>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton size="large" onClick={(e) => setMenuOpen(true)/*setAnchorEl(e.currentTarget)*/}>
                                    <MenuIcon />
                                </IconButton>
                                <Drawer anchor='left' open={menuOpen} onClose={() => setMenuOpen(false)} sx={{zIndex:1302}}>
                                    <MenuItem onClick={() => {navigate(`/user/${user.username}`); setMenuOpen(false);}} sx={{ mt: 2 }}>
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                                            <Avatar src={`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${user?.profile?.avatar}.png`} />
                                            <Typography fontFamily={'Acme'}>{user.username}</Typography>
                                        </Stack>
                                    </MenuItem>
                                    <hr style={{ width: '90%', opacity: '25%' }} />
                                    {pages.map((p) => (
                                        <MenuItem key={p.title} onClick={()=>{p.action(); setMenuOpen(false);}}>
                                            <Typography textAlign={'center'} fontFamily={'Acme'}>{p.title}</Typography>
                                        </MenuItem>
                                    ))}
                                    <Box flexGrow={1}/>
                                    <hr style={{ width: '90%', opacity: '25%' }} />
                                    <MenuItem onClick={() => {navigate('/settings'); setMenuOpen(false);}}>
                                        <Grid container direction={'row'} justifyContent={'space-between'}>
                                            <Typography textAlign={'center'} fontFamily={'Acme'}>Settings</Typography>
                                            <Settings/>
                                        </Grid>
                                    </MenuItem>
                                    <MenuItem onClick={() => {logOut(); setMenuOpen(false);}}>
                                    <Grid container direction={'row'} justifyContent={'space-between'}>
                                            <Typography textAlign={'center'} fontFamily={'Acme'}>Log out</Typography>
                                            <Logout />
                                        </Grid>
                                    </MenuItem>
                                </Drawer>
                            </Box>
                            <Button onClick={onGoHomeClicked} title="home" disableRipple disableElevation sx={{ "&:hover": { backgroundColor: 'transparent' }, mx: 0.5, display: { xs: 'flex', sm: 'none' } }}>
                                <img src={logo} style={{ width: 'auto', height: 48 }} aria-label="ArtRush" />
                            </Button>
                            <Button onClick={onGoHomeClicked} title="home" disableRipple disableElevation sx={{ "&:hover": { backgroundColor: 'transparent' }, mx: 1, display: { xs: 'none', sm: 'flex' } }}>
                                <img src={logo} style={{ width: 'auto', height: 64 }} aria-label="ArtRush" />
                            </Button>
                            <Stack direction='row' spacing={2} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                <Button >
                                    <Typography variant="body1" component="div" onClick={() => {navigate('/games') }} fontFamily={'Acme'}>
                                        Finished Games
                                    </Typography>
                                </Button>
                                <Button>
                                    <Typography variant="body1" component="div" onClick={() => {navigate('/about')}} fontFamily={'Acme'}>
                                        About
                                    </Typography>
                                </Button>
                                <Button href="https://discord.gg/N7faCrMzmf" target="_new" rel="noreferrer noopener">
                                    <Typography variant="body1" component="div" fontFamily={'Acme'}>
                                        Join the Discord
                                    </Typography>
                                </Button>
                            </Stack>
                            <Box sx={{ flexGrow: 1 }} />
                            <Tooltip title={canPlay ? null : "You must verify your email in order to play"}>
                                <ButtonGroup disabled={!canPlay} sx={{ mx: 1 }}>
                                    <Button variant="contained" color="success" onClick={onPlayClicked} sx={{ paddingX: 1 }}>
                                        <PlayArrow style={{ color: '#fff' }} size="large" />
                                        <Typography variant="h6" color="White" fontWeight="700" fontFamily={'Acme'} sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                                            Play!
                                        </Typography>
                                    </Button>
                                    <Button variant="contained" color="primary" sx={{ paddingX: 1 }} onClick={() => setNewGameModalOpen(true)}>
                                        <Add style={{ color: '#fff' }} size='large' />
                                    </Button>
                                </ButtonGroup>
                            </Tooltip>
                            <Button variant="contained" color='secondary' sx={{ mx: 1 }} aria-label="Shop">
                                <Store style={{ color: '#fff' }} size="large" />
                                <Typography variant="h6" color='white' component="div" onClick={() => { navigate('/shop') }} fontFamily={'Acme'} sx={{ ml: 1, flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                                    Shop
                                </Typography>
                            </Button>
                            
                            <AvatarMenu sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
                            <NotificationsDropdown />
                        </Toolbar>
                    </Container>
                    {user?.verifiedEmail ? 
                    <ProgressBar xp={user?.profile.exp} nextXp={user?.rankStats.nextExp} currentXp={user?.rankStats.currExp} currentLevel={user?.profile.rank} tokens={user.tokens} sx={{width:'100%'}}/> :
                    <Typography variant="subtitle1" fontFamily={'Acme'} color={'error'} align="center">You must verify your email to play</Typography>
                    }
                </AppBar>

                <Modal open={playModalOpen} sx={{ overflow: "scroll" }}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'}>
                        <Stack direction={isGameError || isGameLoading ? 'row-reverse' : 'column'} justifyContent={'center'} alignItems={'flex-start'}>
                            <>{
                                isGameError || isGameLoading ?
                                    <Badge badgeContent={<IconButton onClick={playModalClose} variant="contained" color="error" size="large"><Cancel /></IconButton>}>
                                        <Card raised>
                                            <Typography fontFamily={'Acme'} variant="h4" sx={{ m: 3 }} align="center">{errorMessage}</Typography>
                                        </Card>
                                    </Badge>
                                    :
                                    <Play exitGame={playModalClose} newGame={completeGame} error={setErrorMessage} />
                            }</>

                        </Stack>
                    </Box>
                </Modal>

                <Modal open={newGameModalOpen} onClose={newGameModalClose} sx={{ overflowY: 'auto' }}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'}>
                        <Paper sx={{ maxWidth: 512, minWidth: 320 }} align='center'>
                            <NewGame onSubmit={onNewGameSubmit} onClose={newGameModalClose} />
                        </Paper>
                    </Box>
                </Modal>

                <Modal open={completedGameModalOpen} onClose={completedGameModalClose}>

                    <Paper sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: 2,
                        maxWidth: 512, minWidth: 320
                    }}>

                        <Stack direction={'column'} alignItems={'center'} sx={{ m: 2 }}>
                            <Typography variant="h4" fontFamily={'Acme'} align="center">{completedGameComplete ? 'Game completed!' : completedContent ? "Submitted:" : "Skipped Game"}</Typography>
                            <hr style={{ width: '80%', opacity: '25%', marginBottom: '1rem' }} />
                            <>{
                                !completedContent ? <Typography variant="h5" fontFamily={'Acme'} align="center">This panel will not appear for you again</Typography> :
                                    completedPanelType === 'caption' ?
                                        <Typography variant="h5" fontFamily={'Acme'} align="center">{completedContent}</Typography> :
                                        <img style={{ height: 256, width: "auto", objectFit: "contain" }} src={`http://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${completedContent}.png`} alt='Drawing' />
                            }</>
                            <Button variant="contained" color="success" onClick={() => navigate(`/games/id/${completedGameId}`)} sx={{ mt: 2, display:completedGameComplete ? 'flex' : 'none'}}>
                                <Typography variant="h6" color="White" fontWeight="700" fontFamily={'Acme'}>
                                    View Game
                                </Typography>
                            </Button>
                            <Button variant="contained" color="success" onClick={onPlayClicked} sx={{ mt: 2 }}>
                                <PlayArrow style={{ color: '#fff' }} size="large" />
                                <Typography variant="h6" color="White" fontWeight="700" fontFamily={'Acme'}>
                                    Play Again!
                                </Typography>
                            </Button>
                        </Stack>
                    </Paper>
                </Modal>
            </>
        );
    }
    return content;
}

export default BannerLoggedIn