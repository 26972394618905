import { Card, Grid, Skeleton } from '@mui/material'
import React from 'react'

const NotificationLoading = () => {
  return (
    <Card sx={{p:1}} >
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid container item direction={'column'} justifyContent={'space-between'} alignItems={'center'}>
                        <Skeleton variant='text' height={2}/>
                    </Grid>
                </Grid>
     </Card>
  )
}

export default NotificationLoading