import { Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetGamesQuery, useLazyGetGamesQuery } from '../gamesApiSlice';
import { connect } from 'react-redux';
import GameItem from './GameItem';
import PageKeeper from '../../../components/PageKeeper';

const FeedView = () => {
    const params = useParams();
    const location = useLocation();

    const { 
        data: games,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetGamesQuery();


    const navigate = useNavigate();

    const onPageChange = (p) => {
      navigate(`/games/${p}`)
    }

    let content;

    if(isLoading) content = <Typography fontFamily={'Acme'} variant='h6'>Loading...</Typography>;
    if(isError) content = <Typography fontFamily={'Acme'} variant='h6' color={'error'}>{error}</Typography>;
    if(isSuccess){
       const { ids } = games;
       if(ids.length) content = ids.map(g => <GameItem gameId={g} key={g}/>) 
       //console.log(content);
    } 
  return (
    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={1}>
        <Typography fontFamily={'Acme'} variant='h4'>Recent Games</Typography>
        {content}
    </Stack>
  )
}

export default FeedView