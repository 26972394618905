import { Stack, Typography } from '@mui/material'
import React from 'react'

const Contact = () => {
  return (
    <Stack sx={{m:2}} spacing={2}>
      <Typography variant='h3' fontFamily={'Acme'}>Contact Us</Typography>
      <Typography variant='body1' fontFamily={'Acme'}>
        <p>For all inqueries you can click <a style={{color:'violet'}} href='mailto:contact@artru.sh'><b>Contact Us</b></a> or send an email to <a style={{color:'inherit'}} href='mailto:contact@artru.sh'>Contact@ArtRu.sh</a></p>
        <p>Members can also <a style={{color:'violet'}} href='https://discord.gg/N7faCrMzmf'>join our Discord server</a> to get support for site and membership related questions and issues</p>
      </Typography>
    </Stack>
  )
}

export default Contact