import { Logout, Person, Settings } from '@mui/icons-material';
import { Avatar, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendLogoutMutation } from "../../features/auth/authApiSlice";
import { selectCurrentUser } from '../../features/auth/currentUserSlice';

const AvatarMenu = ({sx}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [avatar, setAvatar] = React.useState('');
    const location = useLocation();
    const user = useSelector(selectCurrentUser);
    useEffect(() => {
        if(user)
        setAvatar(`https://artrush-dev-images.nyc3.digitaloceanspaces.com/images/${user.profile.avatar}.png`)
    },[user, location])

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleAvatarClose = () => {
        setAnchorEl(null);
    }
    const logOut = () => {
        sendLogout();
        navigate('/');
    }
    const [sendLogout, {
        isLoading: isLogoutLoading,
        isSuccess: isLogoutSuccess,
        isError: isLogoutError,
        error: logoutError
    }] = useSendLogoutMutation();
    const navigate = useNavigate();


    //if(!user) navigate('/login')
    return (
        <div>
            <IconButton onClick={handleAvatarClick} sx={sx}>
                <Avatar src={avatar} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClick={handleAvatarClose}
                onClose={handleAvatarClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={sx}
            >
                <Typography variant="h6" fontFamily='Acme' align="center">{user?.username}</Typography>
                <hr style={{ width: '80%', opacity: '25%' }} />
                <MenuItem onClick={() => { navigate(`/user/${user?.username}`) }}>
                    <ListItemIcon>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={() => { navigate(`/settings`) }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={logOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )
}

export default AvatarMenu