import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/auth',
                method: 'POST',
                body: { ...credentials}
            })
        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }){
                try {

                    const { data } = await queryFulfilled;
                    //console.log(data);
                    dispatch(logOut());
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState());
                    }, 1000);
                    
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const {data} = await queryFulfilled;
                    //console.log(data);
                    const {accessToken} = data;
                    dispatch(setCredentials({accessToken}));
                } catch (err) {
                    //console.error(err);
                }
            }
        }),

        sendReset: builder.mutation({
            query: ({email}) => ({
                url:'/auth/reset',
                method:'PUT',
                body:{email}
            })
        }),

        resetPassword: builder.mutation({
            query: ({token,username,newPassword}) => ({
                url:'/auth/reset',
                method:'POST',
                body:{token,username,newPassword}
            })
        }),
    })
});

export const {
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation,
    useResetPasswordMutation,
    useSendResetMutation
} = authApiSlice;