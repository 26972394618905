import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetGameQuery, useLazyGetGameQuery, useLikeGameMutation } from './gamesApiSlice';
import { Badge, Box, Button, ButtonGroup, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Modal, SpeedDial, Stack, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/currentUserSlice';
import { AddReaction, Check, Close, Favorite, FavoriteBorder, Flag, PersonAdd } from '@mui/icons-material';
import PanelSample from '../../components/Home/PanelSample';
import AdSenseAd from '../../components/Ads/AdSenseAd';
import PanelSampleAd from '../../components/Ads/PanelSampleAd';
import PanelView from './PanelView';
import emotes from '../panels/Emotes';
import { useSetAvatarMutation } from '../users/usersApiSlice';

const GameView = () => {
  let { gameId } = useParams();

  const [getGame,{
    data: gameData,
    isError: isGameError,
    isLoading: isGameLoading,
    isSuccess: isGameSuccess,
    error: gameError
  }] = useLazyGetGameQuery();

  const [likeGame, {
    data: likeData,
    isError: isLikeError,
    isLoading: isLikeLoading,
    isSuccess: isLikeSuccess,
    error: likeError
  }] = useLikeGameMutation();

  const currUser = useSelector(selectCurrentUser);

  const [setAvatar, {
    isSuccess: isSetAvatarSuccess,
    isLoading: isSetAvatarLoading,
    isError: isSetAvatarError,
    data: setAvatarData,
    error: setAvatarError
  }] = useSetAvatarMutation();

  const [isLiked, setIsLiked] = React.useState(false);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [viewImage, setViewImage] = React.useState(null);
  const [pfpDialogOpen, setPfpDialogOpen] = React.useState(false);
  const [reportDialogOpen, setReportDialogOpen] = React.useState(false);

  const onLikeGame = async () => { await likeGame({ id: gameId, like: !isLiked }) };
  const onOpenPanel = (p) => { setViewImage(p); setViewOpen(true); }

  useEffect(() => {
    getGame(gameId);
  }, [])

  useEffect(() => {
    if(isSetAvatarSuccess){
      setPfpDialogOpen(false);
      setViewOpen(true);
    }
  },[isSetAvatarSuccess])

  useEffect(() => {
    if (isLikeSuccess) setIsLiked(Boolean(likeData.liked))
    //console.log(isLiked);
  }, [isLikeSuccess]);

  useEffect(() => {
    if (isGameSuccess && currUser) {
      //console.log(currUser._id);
      //console.log(gameData.likes);
      setIsLiked(gameData.likes.map((l) => l.toString()).indexOf(currUser._id.toString()) > -1)
    }
  }, [currUser, isGameSuccess]);

  let content;
  if (isGameLoading) content = <Typography variant='h3' fontFamily={'Acme'}>Loading Game...</Typography>
  if (isGameError) content = <Typography variant='h3' fontFamily={'Acme'}>{gameError?.data?.message}</Typography>
  if (isGameSuccess) {
    const panelDates = gameData?.panels?.map((x) => {return new Date(x.createdAt)});
    //console.log(panelDates);
    const time = Math.ceil((Math.max(...panelDates) - Math.min(...panelDates))/(1000 * 60 * 60 * 24));
    const panels = gameData?.panels?.map((p, i, j) => {
      return (

            <PanelSample panel={p} onClick={() => { if (p.panelType === "drawing") onOpenPanel(p) }} key={i} count={i+1} sx={{mx:2}} showLikes={true} onRefreshGame={() => getGame(gameId)}/>

      )
    }

    );
    content =
      <>
      <Stack spacing={2} sx={{m:2}}>
        <Typography fontFamily={'Acme'} variant='h4' fontWeight={700} align='center' >{gameData.gameMode} Game</Typography>
        <Typography fontFamily={'Acme'} variant='h6' align='center' >Finished in {time} days</Typography>
      </Stack>
        <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'center'}>
          <Grid container alignItems={'start'} justifyContent={'center'}>
            <Box sx={{ m:2 }}>
              <PanelSampleAd slot={"9990920567"} />
            </Box>
            {panels}
            <Box sx={{ m: 2 }}>
              <PanelSampleAd slot={"3744930623"} />
            </Box>
          </Grid>
          <Tooltip title='Favorite game' sx={{ mt: 3 }}>
            <IconButton onClick={onLikeGame}>
              {isLiked ? <Favorite sx={{color:'red'}} fontSize='large' /> : <FavoriteBorder color='white' fontSize='large' />}
            </IconButton>
          </Tooltip>
          <AdSenseAd slot={"7297131008"} />
        </Stack>
        <Modal open={viewOpen} onClose={() => setViewOpen(false)}>
          <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{ width: 1, height: '100vh', position: 'relative' }} onClick={() => setViewOpen(false)}>
            <Stack spacing={1}>
              <PanelView content={`${viewImage?.content}.png`} />
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Fab variant='contained' color='secondary' onClick={() => {setPfpDialogOpen(true)}}>
                <AddReaction fontSize='large' color='inherit'/>
              </Fab>
              <Card raised sx={{p:2}}>
                <Typography variant='h5'>
                  {new Date(viewImage?.createdAt)?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric'})}
                </Typography>
              </Card>
              <Fab variant='contained' color='error'>
                <Flag fontSize='large'/>
              </Fab>
              </Stack>
            </Stack>
            </Stack>
            
        </Modal>
        <Dialog open={pfpDialogOpen} onClose={() => {setPfpDialogOpen(false)}} aria-labelledby='Set Profile Picture' aria-describedby='Set Profile Picture'>
          <DialogTitle>
            Set this image as your profile picture?
          </DialogTitle>
          <DialogActions>
            <IconButton onClick={() => {setPfpDialogOpen(false)}}>
              <Close/>
            </IconButton>
            <IconButton onClick={() => {setAvatar(viewImage.content)}}>
              <Check/>
            </IconButton>
          </DialogActions>
        </Dialog>
      </>
  }
  return content;

}

export default GameView