import { Skeleton, Stack } from '@mui/material'
import React from 'react'

const PanelSampleLoading = () => {
  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{p:2}}>
        <Skeleton variant='circular' animation='pulse' width={128} height={128} sx={{mr:1}}/>
        <Skeleton variant='rectangle' animation='pulse' width={256} height={256}/>
    </Stack>
  )
}

export default PanelSampleLoading