import { Notifications } from '@mui/icons-material'
import { Badge, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Notification from '../../features/notifications/Notification'
import NotificationLoading from '../../features/notifications/NotificationLoading'
import { useLazyGetNotificationsQuery, useLazyGetTotalNotificationsQuery, useMarkNotificationsAsReadMutation } from '../../features/notifications/notificationsApiSlice'

const NotificationsDropdown = () => {

  const [markNotificationsAsRead, {
    data: noteReadResponse,
    isSuccess: isNoteReadSuccess,
    isLoading: isNoteReadLoading,
    isError: isNoteReadError,
    error: noteReadError
  }] = useMarkNotificationsAsReadMutation();

  const [getTotalNotifications, {
    data: totalNotifications,
    isSuccess: isTotalNotificationsSuccess,
    isError: isTotalNotificationsError,
    isLoading: isTotalNotificationsLoading,
    error: totalNotificationsError
  }] = useLazyGetTotalNotificationsQuery();

  const [getNotifications, {
    data: notificationsData,
    isSuccess: isNotificationsSuccess,
    isError: isNotificationsError,
    isLoading: isNotificationsLoading,
    error: notificationsError
  }] = useLazyGetNotificationsQuery();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const handleNotificationsClick = async (event) => {
    getNotifications();
    setAnchorEl(event.currentTarget);
  }

  const loading = Array(5).fill(<NotificationLoading />);

  const handleNotificationsClose = async () => {
    setAnchorEl(null);
    const unreadNotifs = notificationsData.filter((n) => {return n.read === false}).map(((n) => { return n._id }));
    if(unreadNotifs.length) markNotificationsAsRead(unreadNotifs);
  }

  useEffect(() => {
    if (isNoteReadSuccess) {
      getTotalNotifications();
      getNotifications(0);
    }
  }, [isNoteReadSuccess])

  useEffect(() => {
    getTotalNotifications();
  }, [location]);

  let notificationComponents
  if (isNotificationsSuccess) {
    notificationComponents = [...notificationsData].slice(0, 5).map((n) => {
      return (
        <MenuItem key={n._id}>
          <Notification content={n.content} object={n.object} objectType={n.objectType} createdAt={n.createdAt} read={n.read} from={n.from} />
        </MenuItem>
      )
    })
  }


  const content =
    <>
      <Badge badgeContent={totalNotifications?.unread ?? 0} color='error'>
        <IconButton onClick={handleNotificationsClick}>
          <Notifications />
        </IconButton>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={Boolean(anchorEl)}
        onClick={handleNotificationsClose}
        onClose={handleNotificationsClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {notificationComponents}
        <hr style={{ width: '80%', opacity: '25%' }} />
        <MenuItem sx={{ justifyContent: 'center' }} onClick={() => { navigate('/notifications') }}>
            <Typography variant='body1' fontFamily={'Acme'} align='center'>See More</Typography>
          </MenuItem>
      </Menu>
    </>
  return content;
}

export default NotificationsDropdown