import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const notificationsAdapter = createEntityAdapter({})
const initialState = notificationsAdapter.getInitialState();

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query({
            query: (page=0) => `/notifications?page=${page}`,
        }),
        markNotificationsAsRead: builder.mutation({
            query: (notifications) => ({
                url: '/notifications',
                method: 'PATCH',
                body: {notifications}
            })
        }),
        getTotalNotifications: builder.query({
            query: () => `/notifications/count`
        })
    })
});

export const {
    useGetNotificationsQuery,
    useLazyGetNotificationsQuery,
    useMarkNotificationsAsReadMutation,
    useGetTotalNotificationsQuery,
    useLazyGetTotalNotificationsQuery
} = notificationsApiSlice;