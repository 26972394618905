import { Cancel, Circle, Delete, RadioButtonChecked, Redo, SkipNext, Undo } from '@mui/icons-material';
import { Badge, Box, Button, ButtonGroup, Card, CardContent, Dialog, Grid, IconButton, Slider, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CanvasDrawer from '../../../components/CanvasDrawer/CanvasDrawer';
import { selectActiveGame } from '../gameSlice';
import Timer from './Timer';

const DrawView = (props) => {
    const palette = props.activePalette;
    const [color, setColor] = React.useState(null);
    const [weight, setWeight] = React.useState(3);
    const [isEraser, setIsEraser] = React.useState(false);
    const [history, setHistory] = React.useState([]);
    const [historyIndex, setHistoryIndex] = React.useState(0);
    const [historyMaxSize, setHistoryMaxSize] = React.useState(5);
    const [canDraw, setCanDraw] = React.useState(true);
    const [colorDialogOpen, setColorDialogOpen] = React.useState(false)
    const [canvasSize, setCanvasSize] = React.useState(window.innerWidth < 512 ? 0.625 : 1);
    const activeGame = useSelector(selectActiveGame);
    //console.log(activeGame)

    const canvasDrawerRef = React.useRef(null);

    const clearCanvas = () => {
        canvasDrawerRef.current.clearCanvas();
        // Reset canvas, no more undo/redo
        setHistory([]);
        setHistoryIndex(0);
    };

    const submitCanvas = () => {
        const drawing = canvasDrawerRef.current.renderSaveCanvas();
        props.submitCanvas(drawing, "drawing");
    }

    const skipGame = () => {
        props.skipGame();
    }

    const renderCanvas = (lines) => {
        canvasDrawerRef.current.renderCanvas(lines);
    }

    const addToHistory = (lines) => {
        const hist = history.slice(0, historyIndex)
        setHistory([...hist, lines]);
        setHistoryIndex(hist.length + 1);
    }

    const canUndo = () => {
        return historyIndex > 0;
    }

    const canRedo = () => {
        return historyIndex < history.length;
    }

    const undo = () => {
        const index = historyIndex - 1;
        setHistoryIndex(index);
        renderCanvas(history.slice(0, index));
    }

    const redo = () => {
        const index = historyIndex + 1;
        setHistoryIndex(index);
        renderCanvas(history.slice(0, index));
    }



    useEffect(() => {
        const handleKeyPress = (event) => {
            // Handle the key press event here
            const keyPressed = event.key;
                switch (keyPressed) {
                    case '1':
                        setWeight(1);
                        break;
                    case '2':
                        setWeight(2);
                        break;
                    case '3':
                        setWeight(3);
                        break;
                    case '4':
                        setWeight(5);
                        break;
                    case '5':
                        setWeight(8);
                        break;
                    case 'z':
                        //if(canUndo) undo();
                        break;
                    case 'x':
                        //if(canRedo) redo();
                        break;
                    case 'm':
                        clearCanvas();
                        break;
                    default:
                        break;
                }
            //console.log('Key pressed:', event.key);
        };

        // Add event listener when component mounts
        window.addEventListener('keydown', handleKeyPress);
        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        setColor(palette.values[0]);
    },[palette]);

    return (
        <>
                <Timer submitGame={submitCanvas} skipGame={skipGame} lastPanel={props.lastPanel} expires={activeGame.expires} message={'Draw:'} close={props.close}>
                    <Stack>
                        <Grid container direction={'row'} display={'flex'} spacing={4}>
                            <Grid item container direction={'column'} xs={2} sx={{ maxHeight: 576, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {palette.values?.map((c) => {
                                    return (
                                        <Grid item key={c}>
                                            <IconButton onClick={() => setColor(c)}>
                                                {c === color ? <RadioButtonChecked sx={{ color: c, fontSize: '3rem' }} /> : <Circle sx={{ color: c, fontSize: '3rem' }} />}
                                            </IconButton>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item sx={{ m: 1 }}>
                                <CanvasDrawer ref={canvasDrawerRef} color={color} weight={weight} bgColor='white' addToHistory={addToHistory} canvasSize={canvasSize*512} />
                            </Grid>
                        </Grid>
                        <Stack direction={'row'} alignItems='center' justifyContent='center' spacing={2} sx={{mx:1}}>
                            <Stack direction='row'>
                                <IconButton onClick={undo} disabled={!canUndo()}>
                                    <Undo fontSize='large' />
                                </IconButton>
                                <IconButton onClick={redo} disabled={!canRedo()}>
                                    <Redo fontSize='large'/>
                                </IconButton>
                            </Stack>
                            <IconButton variant='outlined' sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} onClick={()=>setColorDialogOpen(true)}>
                                <Circle sx={{ color: color}} fontSize='large'/>
                            </IconButton>
                            <Slider size="medium" aria-label="Brush Size" value={weight} valueLabelDisplay="off" min={0.5} step={.1} max={10} onChange={(e) => setWeight(e.target.value)} />
                            <IconButton onClick={clearCanvas}>
                                <Delete fontSize='large' color="error" />
                            </IconButton>
                        </Stack>
                    </Stack>

                </Timer>
            <Dialog open={colorDialogOpen} onClose={()=>setColorDialogOpen(false)} sx={{p:2}}>
                <Grid container sx={{p:2}} spacing={2}>
                    {palette.values?.map((c) => {
                        return (
                            <Grid item key={c}>
                                <IconButton onClick={() => {setColor(c); setColorDialogOpen(false)}}>
                                    {c === color ? <RadioButtonChecked sx={{ color: c, fontSize: '3rem' }}/> : <Circle sx={{ color: c, fontSize: '3rem' }} />}
                                </IconButton>
                            </Grid>
                        );
                    })}
                </Grid>
            </Dialog>
        </>
    )
}

export default DrawView