import { Circle } from '@mui/icons-material'
import { Card, CardContent, Grid, MenuItem, Paper, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Notification = ({ content, object, objectType, createdAt, read, from, small=true, align='center' }) => {
    const navigate = useNavigate();
    const timeSinceNotif = (Date.now() - new Date(createdAt)) / 1000;
    const dateString =
        timeSinceNotif < 60 ? `${Math.floor(timeSinceNotif)} seconds ago` :
            timeSinceNotif < 60 * 60 ? `${Math.floor(timeSinceNotif / 60)} minutes ago` :
                timeSinceNotif < 60 * 60 * 24 ? `${Math.floor(timeSinceNotif / (60 * 60))} hours ago` :
                    new Date(createdAt).toLocaleDateString();

    const onNotificationClick = async () => {
        switch (objectType) {
            case "Game":
                navigate(`/games/id/${object}`);
                break;
            case "User":
                navigate(`/user/${from?.username}`);
                break;
            default:
                break;
        }
    }

    return (
            <Card sx={{p:1, border: read ? "" : "2px solid purple", flexGrow:1, cursor:'pointer'}} onClick={onNotificationClick}>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid container item direction={'column'} justifyContent={'space-between'} alignItems={align}>
                        <Typography variant={small ? 'body1' : 'h4'} fontFamily={'Acme'}>{content}</Typography>
                        <Typography variant={small ? 'body2' : 'h5'} fontFamily={'Acme'}>{dateString}</Typography>
                    </Grid>
                </Grid>
            </Card>
    )
}

export default Notification