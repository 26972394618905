import { IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLazyGetNotificationsQuery, useLazyGetTotalNotificationsQuery, useMarkNotificationsAsReadMutation } from './notificationsApiSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Notification from './Notification';
import NotificationLoading from './NotificationLoading';
import { ArrowBack, ArrowForward, FirstPage, LastPage } from '@mui/icons-material';

const Notifications = () => {
  const params = useParams();
  const page = parseInt(params.page ?? '0')

  const location = useLocation();
  const navigate = useNavigate();
  const [maxPages, setMaxPages] = React.useState(1);
  //const [content, setContent] = React.useState(null);
  let content;

  const [markNotificationsAsRead, {
    data: noteReadResponse,
    isSuccess: isNoteReadSuccess,
    isLoading: isNoteReadLoading,
    isError: isNoteReadError,
    error: noteReadError
  }] = useMarkNotificationsAsReadMutation();

  const [getTotalNotifications, {
    data: totalNotifications,
    isSuccess: isTotalNotificationsSuccess,
    isError: isTotalNotificationsError,
    isLoading: isTotalNotificationsLoading,
    error: totalNotificationsError
  }] = useLazyGetTotalNotificationsQuery();

  const [getNotifications, {
    data: notificationsData,
    isSuccess: isNotificationsSuccess,
    isError: isNotificationsError,
    isLoading: isNotificationsLoading,
    error: notificationsError
  }] = useLazyGetNotificationsQuery();


  useEffect(() => {
    if(page && isNaN(page)) return navigate('/notifications');
    getTotalNotifications()
    getNotifications(page);
  },[location, page]);

  useEffect(() => {
    if(isNotificationsSuccess){
      //setContent(notificationsData.map((n) => {return <Notification content={n.content} object={n.object} objectType={n.objectType} createdAt={n.createdAt} read={n.read} from={n.from} align='start' key={n._id}/>}));
      const unreadNotifs = notificationsData.filter((n) => {return n.read === false}).map(((n) => { return n._id }));
      console.log(unreadNotifs);
      markNotificationsAsRead(unreadNotifs);
    }
  }, [isNotificationsSuccess, notificationsData]);

  useEffect(() => {
    if(isNotificationsLoading){
      //setContent(new Array(20).map((n,i,j)=>{return <NotificationLoading key={i}/>}));
    }
      
  }, [isNotificationsLoading])

  useEffect(() => {
    if(isNotificationsError){
      //setContent(<Typography color={'error'} variant='h5' fontFamily={'Acme'}>{notificationsError.data.message}</Typography>);
  }}, [isNotificationsError])

  useEffect(() => {
    if(isTotalNotificationsSuccess){
      setMaxPages(Math.ceil(totalNotifications.total/20));
    }
  })

  if(isNotificationsLoading)
    content = new Array(20).map((n,i,j)=>{return <NotificationLoading key={i}/>});
  if(isNotificationsError)
    content = <Typography color={'error'} variant='h5' fontFamily={'Acme'}>{notificationsError.data.message}</Typography>;
  if(isNotificationsSuccess)
    content = notificationsData.map((n) => {return <Notification content={n.content} object={n.object} objectType={n.objectType} createdAt={n.createdAt} read={n.read} from={n.from} align='start' key={n._id}/>});
  return (
    <Stack sx={{m:2}} spacing={1}>
      <Typography variant='h3' align='left' fontFamily={'Acme'}>Notifications</Typography>
      {content}
      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
        <IconButton disabled={!page>0} onClick={() => navigate('/notifications/0')}>
          <FirstPage fontSize='large'/>
        </IconButton>
        <IconButton disabled={!page>0} onClick={() => navigate(`/notifications/${(page-1)}`)}>
          <ArrowBack fontSize='large'/>
        </IconButton>
        <Typography variant='h6' fontFamily={'Acme'} sx={{mx:5}}>{`${page+1} of ${maxPages}`}</Typography>
        <IconButton disabled={page>=(maxPages-1)} onClick={() => navigate(`/notifications/${(page+1)}`)}>
          <ArrowForward fontSize='large'/>
        </IconButton>
        <IconButton disabled={page>=(maxPages-1)} onClick={() => navigate(`/notifications/${(maxPages-1)}`)}>
          <LastPage fontSize='large'/>
        </IconButton>
      </Stack>
    </Stack>
    );

}

export default Notifications