import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import GameView from './features/games/GameView';
import Layout from './components/Layout';
import Login from './features/auth/Login';
import Public from './components/Public';
import UsersList from './features/users/UsersList';
import GamesList from './features/games/GamesList';
import UserView from './features/users/UserView';
import SignUpForm from './features/users/SignUpForm';
import Home from './features/users/Home';
import PersistLogin from './features/auth/PersistLogin';
import Prefetch from './features/auth/Prefetch';
import Profile from './features/users/EditProfile';
import NotFound from './components/NotFound';
import Contact from './components/Contact';
import DrawView from './features/games/PlayViews/DrawView';
import Play from './features/games/PlayViews/Play';
import VerifyEmail from './features/users/VerifyEmail';
import { RequireLoginLayout } from './features/auth/RequireLoginLayout';
import Notifications from './features/notifications/Notifications';
import Shop from './features/shop/Shop';
import FeedView from './features/games/FeedView/FeedView';
import About from './components/About';
import ModDashboard from './features/moderation/ModDashboard';
import GamesDashboard from './features/moderation/GamesDashboard';
import Reset from './features/auth/Reset';
import InvitesDashboard from './features/moderation/InvitesDashboard';

function App() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path='/dashboard' element={<ModDashboard/>}>
          <Route path='games' caseSensitive={false} element={<GamesDashboard/>}/>
          <Route path='invites' caseSensitive={false} element={<InvitesDashboard/>}/>
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Public />} />
          <Route path='verify' caseSensitive={false} element={<VerifyEmail />} />
          <Route path='reset' caseSensitive={false} element={<Reset/>}/>
          <Route path='contact' caseSensitive={false} element={<Contact />} />
          <Route path='about' caseSensitive={false} element={<About/>}/>
          <Route path='404' element={<NotFound />} />
          <Route caseSensitive={false} path="user">
            <Route index element={<UsersList />} />
            <Route caseSensitive={false} path=':username'>
              <Route index element={<UserView />} />
            </Route>
          </Route>
          <Route caseSensitive={false} path="login" element={<Login />} />
          <Route caseSensitive={false} path="signup" element={<SignUpForm />} />
          <Route caseSensitive={false} path="/games">
            <Route path=':page?' element={<FeedView />} />
            <Route path='id/:gameId' element={<GameView />} />
          </Route>
          <Route path='shop' caseSensitive='false' element={<Shop />} />
          <Route element={<RequireLoginLayout />}>
            <Route element={<Prefetch />}>
              <Route caseSensitive={false} path="home" element={<Home />} />
              <Route caseSensitive={false} path='notifications'>
                <Route path=':page?' element={<Notifications />} />
              </Route>
              <Route caseSensitive={false} path="settings" element={<Profile />} />
              <Route caseSensitive={false} path='play' element={<Play />} />
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<Navigate to='/404' />} />
      </Route>
    </Routes>
    /*
    <div>
      <GameView palette={['#FF0000','#00FF00','#0000FF','#00FFFF','#FF00FF','#FFFF00','#FEE3D4','#593123','#FFFFFF','#000000']} gamePrompt="Cat with a moustache"/>
    </div>
   */
  );
}

export default App;
