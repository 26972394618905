import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import usePersist from "../../hooks/usePersist";
import { useRefreshMutation } from "./authApiSlice";
import { selectCurrentToken } from "./authSlice";

const PersistLogin = () => {

    const [persist] = usePersist();
    const token = useSelector(selectCurrentToken);
    const effectRan = useRef(false);

    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation();

    const location = useLocation();
    useEffect(() => {
        if(effectRan.current === true || process.env.NODE_ENV !== 'development'){
            const verifyRefreshToken = async () => {
                //console.log('verifying refresh token');
                try{
                    const response = await refresh();
                    //const {accessToken} = response.data;
                    setTrueSuccess(true);
                }
                catch(err){
                    //console.error(err);
                }
            }
            if (!token && persist) verifyRefreshToken();
        }
        return () => effectRan.current = true;

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const verifyRefreshToken = async () => {
          try {
            const response = await refresh();
            setTrueSuccess(true);
          } catch (err) {
            //console.error(err);
          }
        };
      
        if (!effectRan.current && !token && persist) {
          //console.log('Token not found, attempting to retrieve on refresh');
          verifyRefreshToken();
        }
        effectRan.current = true;
      }, [token, persist, refresh]);
      
    let content = <Outlet/>

    if(!persist) {
        //console.log("no persist");
        content = <Outlet/>
    } else if(isLoading) {
        //console.log("loading");
        content = <p>Loading...</p>;
    } else if(isError) {
        //console.error(error);
        
        //content = error.status === 502 ? <p>502 - Bad Gateway</p> : <Navigate to='/login' replace={true} state={{...location, redirected: true}}/>;
    } else if(isSuccess && trueSuccess) {
        //console.log("success");
        content = <Outlet/>;
    } else if(token && isUninitialized) {
        //console.log(`token and uninit = ${isUninitialized}`);
        content = <Outlet/>;
    }
        
    return content;

}
export default PersistLogin;