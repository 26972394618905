import { React, useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken, setCredentials } from './authSlice'
import { useLoginMutation, useSendResetMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'

import { Box, Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Modal, Paper, Stack, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [viewPw, setViewPw] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [persist, setPersist] = usePersist();

  //Block disposable mailboxes
  //Makes sure it's in email format
  const EMAIL_REGEX = /^(?!.*@(guerrillamail\.|guerrillamailblock\.|10minutemail\.|mailinator\.|dispostable\.|discard\.|spambog\.|fakeinbox\.|maildrop\.|tempmail\.|getnada\.|trbvm\.|mailnesia\.|yopmail\.|mvrht\.|meltmail\.|zetmail\.|fleckens\.|pookmail\.|tmails\.|mohmal\.|owlpic\.|mailsac\.|anonbox\.|trashmail\.|mt2015\.))\S+@\S+\.\S+$/

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  const [login, { isLoading }] = useLoginMutation();
  const [sendReset, {
    data: resetData,
    isSuccess: isResetSuccess,
    isError: isResetError,
    error: resetError
  }] = useSendResetMutation();


  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email]);

  useEffect(() => {
    userRef.current.focus()
  }, []);
  useEffect(() => {
    setErrMsg();
  }, [username, password]);
  useEffect(() => {
    if (token) navigate('/home');
  }, [token])
  const handleToggle = () => setPersist(prev => !prev);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken } = await login({ username, password }).unwrap();
      dispatch(setCredentials({ accessToken }));
      setUsername('');
      setPassword('');
      navigate('/home');
    } catch (err) {
      if (!err.status) setErrMsg('No Server Response');
      else setErrMsg(err.data?.message);
      errRef.current.focus();
    }
  }




  const content = (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ my: 4 }}>
          <Typography variant='h3'>Log In</Typography>
          <Typography color="error" sx={{ my: 1 }} ref={errRef} aria-live="assertive">{errMsg}</Typography>
          <FormGroup>
            <Stack direction="column" alignItems="center" justifyContent="center">
              <TextField
                label='Username'
                value={username}
                onChange={e => setUsername(e.target.value)}
                inputProps={{ maxLength: 16 }}
                sx={{ my: 1 }}
                variant="standard"
                ref={userRef}
                fullWidth
              />
              <TextField
                label='Password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                inputProps={{ maxLength: 32 }}
                type={viewPw ? 'text' : 'password'}
                sx={{ my: 1 }}
                variant="standard"
                autoComplete='on'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setViewPw(!viewPw)} disableRipple>
                        {viewPw ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <ButtonGroup sx={{ my: 1 }} fullWidth>
                <FormControlLabel control={<Button onClick={() => setForgotModalOpen(true)}>Forgot Password</Button>} />
                <FormControlLabel control={<Button variant='contained' onClick={handleSubmit}>Submit</Button>} />
              </ButtonGroup>
              <FormControlLabel control={<Checkbox checked={persist} onChange={handleToggle} />} label='Stay signed in' />
            </Stack>
          </FormGroup>
        </Grid>
      </form>
      <Dialog open={forgotModalOpen} onClose={() => setForgotModalOpen(false)}>
        <DialogTitle align='center'>Please enter your email</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant='inherit' align='center'>If you have provided a valid email a password reset link will be sent to you</Typography>
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} error={!validEmail} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setEmail(''); setForgotModalOpen(false); }}>Close</Button>
          <Button disabled={!validEmail} onClick={() => { sendReset({ email }); setEmail(''); setForgotModalOpen(false); }}>Reset</Button>
        </DialogActions>

      </Dialog>
    </>

  )
  return content
}

export default Login